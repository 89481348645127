import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated, isUserAdmin, isUserRoleNewUser } from "../../utils";

export default function PrivateRoute({ children }) {
  const hasAuthenticated = isAuthenticated();
  const isUserRoleAdmin = isUserAdmin(["Admin", "superadmin", "mainadmin"]);
  const isUserRoleNewUserr = isUserRoleNewUser();
  return (
    <>
      {hasAuthenticated && isUserRoleAdmin ? (
        children
      ) : !hasAuthenticated ? (
        <Navigate to="/login" />
      ) : isUserRoleNewUserr ? (
        <Navigate to="/profile" />
      ) : (
        <Navigate to="/subscriptions" />
      )}
    </>
  );
}
