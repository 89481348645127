import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  Popover,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  Grid,
  TextField,
} from "@mui/material";

import {
  StarBorderRounded,
  StarRounded,
  BorderColor,
  AddCircle,
  Delete,
} from "@mui/icons-material";
import CaptionFormCard from "../CaptionFormCard/CaptionFormCard";
import {
  FormatExecutionString,
  postCardForPackage,
  setHeaders,
} from "../../utils";

import "./CardExersice.css";

import { CardContext } from "../../Contexts/CardContext";
import { PackageID } from "../../Contexts/PackageID";

import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import Swal from "sweetalert2";
import { HOST_NAME } from "../../config";
import axios from "axios";

const popoverStyle = {
  width: "35vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  right: "0%",
};

const CardExercise = ({
  keyID,
  isFromLibrary,
  mainPicturePath,
  name,
  subject1,
  subject2,
  preparation,
  otherDescription,
  execution,
  exercise_Files,
  videos,
  isShowTreemenu,
  isCustomizedTab,
  isForTemplate = false,
  templateID,
  isStarred,
  onRemoveExercise,
}) => {
  const { selectedAddCard, updateselectedAddCardVariable } =
    useContext(CardContext);
  const { isTabPanelOpen, setIsTabPanelOpen } = useContext(PackagesMadeContext);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isStared, setIsStared] = useState(isStarred);

  const [exerciseDetails, setExerciseDetails] = useState(
    isForTemplate
      ? `<div>
          <span>${subject1}</span>
          <ul>${FormatExecutionString(preparation)}</ul>
          <span>${subject2}</span>
          <ul>${FormatExecutionString(execution)}</ul>
        </div>`
      : ""
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateParams, setTemplateParams] = useState([]);

  function closeModalDetails() {
    setIsModalOpen(false);
  }

  const postSttar = (id, newValue) => {
    const headers = setHeaders();

    axios
      .post(
        `${HOST_NAME}/api/v1/Exercise_Favorite?starnewValue=${newValue}&ExerciseId=${id}`,
        null,
        {
          headers,
        }
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    postSttar(keyID, isStared);
  }, [isStared]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [isHovered, setIsHovered] = useState(false);

  function addCard(isPanelOpen, card, isCustomized) {
    if (isPanelOpen) {
      updateselectedAddCardVariable((prev) => [
        ...prev,
        { ...card, isCustomized },
      ]);
    } else {
      setIsTabPanelOpen(true);
      updateselectedAddCardVariable((prev) => [
        ...prev,
        { ...card, isCustomized },
      ]);
    }
  }

  function handleAddCard(isPanelOpen, card) {
    if (isFromLibrary) {
      const headers = setHeaders();

      axios
        .get(`${HOST_NAME}/api/v1/Exercise/GetCustomizedStatus${card.keyID}`, {
          headers,
        })
        .then((res) => {
          if (res.data.success) {
            Swal.fire({
              title: "انتخاب نوع تمرین",
              text: "برای این تمرین مورد شخصی سازی شده نیز وجود دارد، کدام مورد را میخواهید اضافه کنید؟",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "پیشفرض",
              denyButtonText: "شخصی سازی شده",
              denyButtonColor: "#7066E0",
              cancelButtonText: "بستن",
            }).then((result) => {
              if (result.isConfirmed) {
                addCard(isPanelOpen, card, false);
              } else if (result.isDenied) {
                addCard(isPanelOpen, card, true);
              }
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (!err.response.data.success) {
            addCard(isTabPanelOpen, card, false);
          }
        });
    } else {
      if (isCustomizedTab) {
        addCard(isPanelOpen, card, true);
      } else {
        addCard(isPanelOpen, card, false);
      }
    }
  }

  function handleShowDetails(event) {
    if (isForTemplate) {
      setIsModalOpen(true);
      const headers = setHeaders();

      axios
        .get(
          `${HOST_NAME}/api/v1/Template/${templateID}/Exercises/${keyID}/Parameters`,
          {
            headers,
          }
        )
        .then((res) => res.status === 200 && setTemplateParams(res.data))
        .catch((err) => console.log("err", err));
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  function removeFromCustomized() {
    Swal.fire({
      icon: "question",
      title: "آیا از حذف این تمرین مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();

        axios
          .delete(`${HOST_NAME}/api/v1/Exercise/DeleteCustomized?id=${keyID}`, {
            headers,
          })
          .then((res) => {
            if (res.status === 200) {
              onRemoveExercise();
            } else {
              Swal.fire({ icon: "error", title: "در حذف تمرین مشکلی پیش آمد" });
            }
          })
          .catch((err) => console.log("err", err));
      }
    });
  }

  return (
    <>
      <Card
        className={`card-container ${
          isShowTreemenu ? "treemenu-activated" : null
        }`}
        sx={{ p: 2, bgcolor: "background.paper", position: "relative" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardHeader
          title={<BorderColor fontSize="large" onClick={handleShowDetails} />}
          sx={{ cursor: "pointer" }}
          action={
            <CardActions disableSpacing>
              <IconButton
                aria-label="add to favorites"
                onClick={() => setIsStared((prev) => !prev)}
              >
                {isStared ? (
                  <StarRounded fontSize="large" style={{ color: "orange" }} />
                ) : (
                  <StarBorderRounded fontSize="large" />
                )}
              </IconButton>

              {isCustomizedTab && (
                <IconButton
                  aria-label="remove from customizes"
                  onClick={removeFromCustomized}
                >
                  <Delete fontSize="large" />
                </IconButton>
              )}
            </CardActions>
          }
        />

        <CardMedia
          component="img"
          height="100px"
          width="100%"
          style={{ objectFit: "contain" }}
          onClick={handleShowDetails}
          image={mainPicturePath}
          alt={`${name} image`}
        />
        <CardContent style={{ padding: "10px 0" }}>
          <Typography
            fontSize="1.4rem"
            textAlign="center"
            fontWeight={600}
            variant="body2"
            color="text.dark"
            fontFamily="IRANSans"
          >
            {name}
          </Typography>

          {isHovered &&
            (!selectedAddCard.some((card) => card.keyID === keyID) ? (
              <AddCircle
                fontSize="large"
                color="primary"
                onClick={() =>
                  handleAddCard(isTabPanelOpen, {
                    keyID,
                    mainPicturePath,
                    name,
                  })
                }
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  backgroundColor: "primary",
                  color: "primary",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            ) : (
              <div
                style={{
                  fontFamily: "IRANSans",
                  color: "#e44da5",
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  backgroundColor: "primary",
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
              >
                این تمرین افزوده شده است.
              </div>
            ))}
        </CardContent>

        <CardActions disableSpacing></CardActions>
      </Card>
      {isForTemplate ? (
        <Dialog
          className="exercise-dialog"
          open={isModalOpen}
          onClose={closeModalDetails}
        >
          <div className="header">
            <h6>{name}</h6>
            <button onClick={closeModalDetails}>بستن</button>
          </div>
          <img src={mainPicturePath} alt={mainPicturePath} />
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "2rem" }}
          >
            {templateParams.map((param) => (
              <>
                <Grid item xs={6}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    label="عنوان"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={param.paramName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    label="مقدار"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={param.paramValue}
                  />
                </Grid>
              </>
            ))}
          </Grid>

          <div
            className="exercise-details"
            dangerouslySetInnerHTML={{
              __html: exerciseDetails,
            }}
          ></div>
          {videos.map((video) => (
            <div key={video.keyID} className="video-container">
              <video width="100%" controls>
                <source src={video.fullpath} type="video/mp4" />
                مرورگر شما ویدئو را پشتیبانی نمی‌کند.
              </video>
              <div className="content">
                <span>{video.videoTitle}</span>
                <p>{video.videoDescription}</p>
              </div>
            </div>
          ))}
          {exercise_Files.map((file) => (
            <div key={file.keyID} className="file-container">
              <img src={file.fullpath} alt={file.fileTitle} />
              <div className="content">
                <span>{file.fileTitle}</span>
                <p>{file.fileDescription}</p>
              </div>
            </div>
          ))}
        </Dialog>
      ) : (
        <Popover
          className="card-exercise"
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: popoverStyle,
          }}
        >
          <div
            style={{ padding: "16px", paddingTop: "0", right: "0", top: "0" }}
          >
            <CaptionFormCard isCustomizedTab={isCustomizedTab} keyID={keyID} />
          </div>
        </Popover>
      )}
    </>
  );
};

export default CardExercise;
