import React, { useEffect, useState } from "react";

import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "./Galleries.css";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import axios from "axios";
import { HOST_NAME } from "../../config";
import { formatDate } from "../../utils";
import { Box, CircularProgress } from "@mui/material";

export default function Galleries() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState();
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const limit = 9;
    setIsLoading(true);
    axios
      .get(
        `${HOST_NAME}/api/v1/Gallery/GetActive?pageNumber=${currentPage}&pageSize=${limit}`
      )
      .then((res) => {
        if (res.status === 200) {
          setGalleries(res.data.gallerylist);
          setPagesCount(Math.ceil(res.data.totalcount / limit));
          setIsLoading(false);
        }
      })
      .catch((err) => console.log("err", err));
  }, [currentPage]);
  return (
    <>
      <ResponsiveNavbar />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            margin: "1rem",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <h1>گالری رویدادها</h1>
          {galleries.length > 0 ? (
            <>
              <div className="galleries">
                {galleries.map((image) => (
                  <div key={image.keyID} className="single-gallery-container">
                    <img src={image.mainPicPath} alt="gallery" />
                    <div className="gallery-content">
                      <h2>{image.title}</h2>
                      <p>{image.description}</p>
                      <span>{formatDate(image.eventDate)}</span>
                      <Link to={`/gallery/${image.keyID}`}>مشاهده تصاویر</Link>
                    </div>
                  </div>
                ))}
              </div>

              <MuiRTL>
                <Stack
                  spacing={2}
                  direction="rtl"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <Pagination
                    count={pagesCount}
                    color="primary"
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                  />
                </Stack>
              </MuiRTL>
            </>
          ) : (
            <p className="not-found-err">گالری یافت نشد.</p>
          )}
        </>
      )}
      <Footer />
    </>
  );
}
