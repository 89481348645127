import axios from "axios";
import { useEffect, useState } from "react";
import { setHeaders } from "../utils";

export default function useTreeMenu(link) {
  const [treeMenu, setTreeMenu] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    getTreeMenu();
  }, []);

  function getTreeMenu() {
    const headers = setHeaders();
    axios.get(link, { headers }).then((res) => {
      console.log("res .data", res.data);
      setTreeMenu(buildTree(res.data));
      setAllData(res.data);
    });
  }

  function buildTree(data) {
    let lookup = {};

    data.forEach((item) => {
      lookup[item.keyID] = { ...item, children: [] };
    });

    let tree = [];

    data.forEach((item) => {
      if ([-1, 0, null].includes(item.fK_ParentID)) {
        tree.push(lookup[item.keyID]);
      } else if (lookup[item.fK_ParentID]) {
        lookup[item.fK_ParentID].children.push(lookup[item.keyID]);
      }
    });
    console.log("tree", tree);
    return tree;
  }

  return { treeMenu, allData };
}
