import React, { useState, useEffect } from "react";
import { Modal, Paper } from "@mui/material";
import axios from "axios";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";

import "../ManageExercise/ModalExcer.css";

function ModalEdu({ isOpen, onClose, Idd, refresh, educationLimit }) {
  const initialValues = {
    title: "",
    content: "",
    subTitle: "",
    subContent: "",
    educationDate: "",
    educationCategoryID: 0,
  };
  const [educationData, setEducationData] = useState(initialValues);

  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(`${HOST_NAME}/api/v1/Education/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setEducationData(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setEducationData(initialValues);
    }
  }, [Idd]);

  const updateEducation = async (e) => {
    e.preventDefault();
    const headers = setHeaders();

    axios
      .put(
        `${HOST_NAME}/api/v1/Education`,
        {
          keyID: Idd,
          ...educationData,
        },
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "آموزش با موفقیت ویرایش شد",
          });
          refresh();
          setEducationData(initialValues);
          onClose();
        }
      })
      .catch((err) => console.log("err", err));
  };

  const insertEducation = async (e) => {
    e.preventDefault();
    const headers = setHeaders();

    axios
      .post(
        `${HOST_NAME}/api/v1/Education`,
        {
          ...educationData,
          keyID: -1,
          educationDate: new Date().toISOString(),
          educationCategoryID: 0,
        },
        { headers }
      )
      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "آموزش با موفقیت ایجاد شد",
          });
          refresh();
          setEducationData(initialValues);
          onClose();
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          Swal.fire({
            icon: "error",
            title: `نمی توانید بیشتر از ${educationLimit} آموزش ایجاد کنید.`,
          });
        }
      });
  };

  return (
    <Modal className="modal-add-edit" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش آموزش" : "ایجاد آموزش"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <form className="ModalExcer-form">
            <input
              type="text"
              placeholder="عنوان آموزش"
              value={educationData.title}
              onChange={(e) =>
                setEducationData({ ...educationData, title: e.target.value })
              }
            />

            <textarea
              type="text"
              placeholder="توضیحات آموزش"
              value={educationData.content}
              onChange={(e) =>
                setEducationData({
                  ...educationData,
                  content: e.target.value,
                })
              }
              rows={6}
            />

            <button onClick={Idd > 0 ? updateEducation : insertEducation}>
              {Idd > 0 ? "ویرایش آموزش" : "ایجاد آموزش"}
            </button>
          </form>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalEdu;
