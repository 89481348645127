import * as React from "react";
import { styled, FormGroup, FormControlLabel, Switch } from "@mui/material";
import "./SwitchButton.css";
import {
  Android12Switch,
  changeYear,
  getFullDate,
  setHeaders,
} from "../../utils";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function SwitcheButton({
  stausShow,
  paintID,
  packageIDSelected,
}) {
  const handleChange = (event) => {
    const todayDate = new Date();
    const newStatus = event.target.checked;
    const headers = setHeaders();

    const newStatusActivePackage = {
      fK_PackageId: packageIDSelected,
      isActive: newStatus,
      startDate: getFullDate(),
      enddate: changeYear(1),
    };

    axios
      .put(
        `${HOST_NAME}/api/v1/Patient/${paintID}/Packages`,
        newStatusActivePackage,
        {
          headers,
        }
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Android12Switch defaultChecked={stausShow} onChange={handleChange} />
        }
      />
    </FormGroup>
  );
}
