import "./style.css";
import { MdOutlineCancel } from "react-icons/md";
export default function CustomModal({ children, setIsOpen }) {
  return (
    <div className="modal ">
      <button onClick={(e) => setIsOpen((prevState) => !prevState)}>
        <MdOutlineCancel />
      </button>
      {children}
    </div>
  );
}
