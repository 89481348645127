import React, { useState, useEffect, useCallback } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";

// Contexts
import { CardProvider } from "../src/Contexts/CardContext";
import { EducationSelectedInModalInexPageProvider } from "./Contexts/EducationSelectedInModalInexPageContext";
import { CreatedNewExersiceCardContextProvider } from "./Contexts/CreatedNewExersiceCardContext";
import { PackagesMadeProvider } from "./Contexts/PackagesMade";
import { SelectedClientProvider } from "./Contexts/SelectedClientContext";
import { CardExersiceContextProvider } from "./Contexts/CardsExesciseContext";
import { PackageIDProvider } from "./Contexts/PackageID";
import { SelectionEducationIDProvider } from "./Contexts/SelectionEducationID";
import { UserLoginCodeProvider } from "./Contexts/UserLoginCode";
import SubscriptionProvider from "./Contexts/Subscriptions";

import AuthContext from "./Contexts/authContext";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./App.css";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [userInfose, setUserInfose] = useState({});
  const [localStorageToken, setLocalStorageToken] = useState("");

  const login = useCallback((userInfose, token) => {
    setToken(token);
    setIsLoggedIn(true);
    setUserInfose(userInfose);
    localStorage.setItem("user", JSON.stringify({ token }));
  });

  const logout = useCallback(() => {
    setToken(null);
    setUserInfose({});
    localStorage.removeItem("user");
  });

  useEffect(() => {
    const localStorageData = JSON.stringify(localStorage.getItem("user"));
    setLocalStorageToken(localStorageData);
    if (!localStorageData) {
      fetch(``, {
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
        .then((res) => res.json())
        .then((userdata) => {
          setIsLoggedIn(true);
          setUserInfose(userdata);
        });
    }
  }, []);

  const router = useRoutes(routes);

  return (
    <UserLoginCodeProvider>
      <SelectionEducationIDProvider>
        <PackageIDProvider>
          <CardExersiceContextProvider>
            <SelectedClientProvider>
              <PackagesMadeProvider>
                <CreatedNewExersiceCardContextProvider>
                  <EducationSelectedInModalInexPageProvider>
                    <CardProvider>
                      <SubscriptionProvider>
                        <div>
                          <AuthContext.Provider
                            value={{
                              isLoggedIn,
                              token,
                              userInfose,
                              login,
                              logout,
                            }}
                          >
                            {router}
                          </AuthContext.Provider>
                        </div>
                      </SubscriptionProvider>
                    </CardProvider>
                  </EducationSelectedInModalInexPageProvider>
                </CreatedNewExersiceCardContextProvider>
              </PackagesMadeProvider>
            </SelectedClientProvider>
          </CardExersiceContextProvider>
        </PackageIDProvider>
      </SelectionEducationIDProvider>
    </UserLoginCodeProvider>
  );
}
