import React, { useState } from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import RightSideEducationPage from "../../Components/RightSideEducationPage/RightSideEducationPage";
import LeftSideEducationPage from "../../Components/LeftSideEducationPage/LeftSideEducationPage";

export default function Education() {
  const [isLoadListEducation, setIsLoadListEducation] = useState(false);

  return (
    <>
      <ResponsiveNavbar />

      <div className="mainSection" style={{ backgroundColor: "#f8f8f8" }}>
        <RightSideEducationPage isLoadListEducation={isLoadListEducation} />
      </div>
    </>
  );
}

