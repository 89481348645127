import { Cancel } from "@mui/icons-material";
import { Grid, IconButton, TextField } from "@mui/material";

export default function SingleParameter({
  param,
  isReadonly,
  handleParamNameChange,
  handleParamValueChange,
  handleRemoveParametersIncludes,
  hasDeleteBtn,
}) {
  return (
    <Grid container spacing={2} alignItems="center" style={{ padding: "1rem" }}>
      <Grid item sm={6} xs={10}>
        <TextField
          style={{ fontFamily: "IRANSans" }}
          label="عنوان"
          variant="filled"
          fullWidth
          InputProps={{
            readOnly: isReadonly,
          }}
          value={param.paramName}
          onChange={(e) => handleParamNameChange(param.keyID, e.target.value)}
        />
      </Grid>
      <Grid item sm={4} xs={10}>
        <TextField
          style={{ fontFamily: "IRANSans" }}
          label="مقدار"
          variant="filled"
          fullWidth
          InputProps={{
            readOnly: isReadonly,
          }}
          value={param.paramValue}
          onChange={(e) => handleParamValueChange(param.keyID, e.target.value)}
        />
      </Grid>
      {hasDeleteBtn && (
        <Grid item xs={1}>
          <IconButton
            onClick={() => handleRemoveParametersIncludes(param.keyID)}
            color="secondary"
          >
            <Cancel style={{ color: "rgba(176,56,60,255)" }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
