import React, { useState, useContext, useEffect } from "react";
import List from "@mui/material/List";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import FileUploader from "../FileUploader/FileUploader";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import MuiRTL from "../MuiRTL/MuiRTL";

import "./ListItemsEducationPage.css";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { CardExersiceContext } from "../../Contexts/CardsExesciseContext";
import ListItemEducationIndex from "../LeftSide/ListItemsEducationIndex/ListItemEducationIndex";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyList = ({ getEducationList, dataEducation, isModalEducation }) => {
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(dataEducation);
  }, [dataEducation]);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [seleEduKeyIds, setSeleEduKeyIds] = useState([]);
  const { keyIdItemsEducation } = useContext(CardExersiceContext);

  const handleTitleChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  const handleConfirmClick = (id) => {
    const updatedItem = {
      keyID: id,
      title: editedTitle,
      content: editedDescription,
      subTitle: "string",
      subContent: "string",
      educationDate: "2023-10-28T20:16:23.454Z",
      educationCategoryID: keyIdItemsEducation,
    };

    const headers = setHeaders();

    axios
      .put(`${HOST_NAME}/api/v1/Education`, updatedItem, {
        headers,
      })
      .then((res) => {
        res.status === 200 && getEducationList();
      })
      .catch((error) => {
        console.error("خطا در ارتباط با سرور: ", error);
      });

    setSeleEduKeyIds([...seleEduKeyIds, updatedItem.keyID]);

    if (itemList) {
      const updatedItemList = itemList.map((item) =>
        item.keyID === updatedItem.keyID ? updatedItem : item
      );
      setItemList(updatedItemList);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openAlertForRepeat, setopenAlertForRepeat] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleCloseAlertRepeat = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenAlertForRepeat(false);
  };

  return (
    <div>
      <MuiRTL>
        <List style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
          {itemList &&
            itemList.map((item) => (
              <ListItemEducationIndex key={item.keyID} {...item} isForBuilder />
            ))}
        </List>

        <Dialog open={open} onClose={handleClose}>
          <DialogContent fullWidth="lg">
            <TextField
              style={{ fontFamily: "IRANSans" }}
              fullWidth
              value={editedTitle}
              onChange={handleTitleChange}
            />
            <TextField
              style={{ fontFamily: "IRANSans" }}
              fullWidth
              multiline
              rows={8}
              value={editedDescription}
              onChange={handleDescriptionChange}
            />
            <div>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "30%" }}
                />
              )}

              <FileUploader
                setFile={setFile}
                style={{
                  padding: "10px",
                  border: "1px",
                  borderRadius: "5px",
                  backgroundColor: "#f8f8f8",
                }}
              >
                <FileUploadIcon fontSize="large" />
                ارسال فایل
              </FileUploader>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleConfirmClick(selectedItem.keyID);
                handleClick();
              }}
              style={{ backgroundColor: "#1976d2" }}
            >
              تایید
            </Button>
            <Button
              onClick={handleClose}
              style={{ backgroundColor: "#ff8a8a" }}
            >
              بستن
            </Button>
          </DialogActions>
        </Dialog>

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="success"
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.5rem",
                width: "100%",
              }}
            >
              {selectedItem && selectedItem.title} افزوده شد !
            </Alert>
          </Snackbar>
        </Stack>

        <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            open={openAlertForRepeat}
            onClose={handleCloseAlertRepeat}
            autoHideDuration={3000}
          >
            <Alert
              onClose={handleCloseAlertRepeat}
              severity="warning"
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.5rem",
                width: "100%",
              }}
            >
              این مورد قبلا افزوده شده !
            </Alert>
          </Snackbar>
        </Stack>
      </MuiRTL>
    </div>
  );
};

export default MyList;
