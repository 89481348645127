import React, { useEffect, useState, useContext } from "react";
import "./RightSideEducationPage.css";
import Treemenu from "../Treemenu/Treemenu";
import SearchBar from "../SearchBar/SearchBar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MuiRTL from "../MuiRTL/MuiRTL";
import { CardExersiceContext } from "./../../Contexts/CardsExesciseContext";
import { PackageID } from "../../Contexts/PackageID";
import Dialog from "@mui/material/Dialog";
import MuiAlert from "@mui/material/Alert";
import DisplayImages from "../DisplayImages/DisplayImages";
import useEducationFiles from "../../Hooks/useEducationFiles";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function RightSideEducationPage({ isLoadListEducation }) {
  const {
    setEducationID,
    educationData,
    setEducationData,
    educationFiles,
    setEducationFiles,
  } = useEducationFiles();

  const [listContent, setListContent] = useState([]);
  const [countPage, setCountPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [treeMenuDataEducation, setTreeMenuDataEducation] = useState("");
  const { keyIdItemsEducation } = useContext(CardExersiceContext);
  const { packageID } = useContext(PackageID);
  const [dataCount, setDataCount] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  function getFormat(file) {
    return file.mainPicturePath.split(".").pop();
  }

  useEffect(() => {
    getAllDataTreeMenu();
  }, []);

  const getAllDataTreeMenu = () => {
    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/EducationCtg`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          const treeMenuDataWithShowAll = [
            {
              keyID: -1,
              ctgName: "نمایش همه",
              fK_ParentID: null,
            },
            ...res.data,
          ];

          setTreeMenuDataEducation(treeMenuDataWithShowAll);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const getEducationList = (keyId, pageNumber, package_ID) => {
    const headers = setHeaders();
    setIsLoading(true);

    const pageSize = 9;
    const keyIDArray = Array.isArray(keyId) ? keyId : [keyId];
    const keyIDString = keyIDArray.join(" ");
    axios
      .get(
        `${HOST_NAME}/api/v1/Education/${
          keyIDString ? keyIDString : "-1"
        },${pageNumber},${pageSize},${package_ID}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setListContent(res.data.educationlist);
          setIsLoading(false);
          setCountPage(Math.ceil(res.data.totalcount / pageSize));
          setDataCount(res.data.totalcount);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getEducationListCondition();
  }, [currentPage, keyIdItemsEducation, packageID]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setItemList(listContent);
  }, [listContent]);

  useEffect(() => {
    if (isLoadListEducation) {
      getEducationListCondition();
    }
  }, [isLoadListEducation]);

  function getEducationListCondition() {
    if (packageID) {
      if (!currentPage) {
        getEducationList(keyIdItemsEducation, 1, packageID);
      } else {
        getEducationList(keyIdItemsEducation, currentPage, packageID);
      }
    } else {
      if (!currentPage) {
        getEducationList(keyIdItemsEducation, 1, 0);
      } else {
        getEducationList(keyIdItemsEducation, currentPage, 0);
      }
    }
  }

  const handleSearch = (searchValue) => {
    if (searchValue.length >= 1) {
      const headers = setHeaders();

      const pageSize = 8;
      setIsLoading(true);

      axios
        .get(
          `${HOST_NAME}/api/v1/Education/GetBYFilter?pageNumber=${currentPage}&pageSize=${pageSize}&stringFilter=${searchValue}`,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setListContent(res.data.educationlist);
            setIsLoading(false);
            setCountPage(Math.ceil(res.data.totalcount / pageSize));
          }
        })
        .catch((e) => console.log(e));
    } else {
      getEducationListCondition();
    }
  };

  return (
    <div
      className="right-side__mainSection"
      style={{
        display: "flex",
        padding: "1.5rem",
        borderLeft: "2px dashed rgba(0, 0, 0, 0.12)",
      }}
    >
      <div className="treeMenu__education-section">
        <Treemenu data={treeMenuDataEducation} />
      </div>
      <div className="showInformation__education-section">
        <SearchBar handleSearch={handleSearch} />

        <div className="education-page-options">
          <button onClick={() => setIsOpenFilter(true)} className="filter-btn">
            <TuneOutlinedIcon /> فیلتر
          </button>

          <Dialog
            className="filter-dialog"
            open={isOpenFilter}
            onClose={() => setIsOpenFilter(false)}
          >
            <Treemenu data={treeMenuDataEducation} />
          </Dialog>
          <span>همه ی موارد پیدا شده : {dataCount}</span>
        </div>

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              margin: "1rem",
            }}
          >
            ` <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="educations-container">
              {listContent?.map((item) => (
                <React.Fragment key={item.keyID}>
                  <div
                    onClick={() => {
                      setEducationID(item.keyID);
                      setEducationData(item);
                    }}
                    className="education-wrapper"
                  >
                    <div className="education-images-container">
                      <DisplayImages item={item} />
                    </div>

                    <div className="single-education-content">
                      <span className="education-title">{item.title}</span>
                      <span className="education-format">
                        نوع فایل: ({getFormat(item)})
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>

            <Dialog
              className="education-file-dialog"
              open={educationFiles}
              onClose={() => {
                setEducationID(0);
                setEducationData(null);
                setEducationFiles(null);
              }}
            >
              <div className="education-file-header">
                <span>{educationData?.title}</span>
                <p>{educationData?.content}</p>
              </div>

              <ul className="files-container">
                {educationFiles?.map((file) => (
                  <li>
                    <a
                      download={`${file.fileName}.${file.fileType}`}
                      href={file.filesAddress}
                    >
                      {file.fileName}.{file.fileType}
                    </a>
                  </li>
                ))}
              </ul>
            </Dialog>
            <div>
              <MuiRTL>
                <Stack
                  spacing={2}
                  direction="rtl"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <Pagination
                    count={countPage}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </MuiRTL>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
