import React, { useEffect, useState } from "react";
import { Modal, Paper } from "@mui/material";
import CustomTreeMenu from "../../Components/CustomTreeMenu/CustomTreeMenu";
import useTreeMenu from "../../Hooks/useTreeMenu";
import { MdAddToPhotos, MdOutlineCancelPresentation } from "react-icons/md";
import axios from "axios";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";

import "./ModalGroup.css";

export default function ModalGroup({
  isOpen,
  onClose,
  content,
  getCategoriesLink,
  setCategoriesLink,
  treeLink,
  field,
}) {
  const { treeMenu, allData } = useTreeMenu(`${HOST_NAME}/api/v1/${treeLink}`);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [contentCategories, setContentCategories] = useState([]);

  useEffect(() => {
    getAllContentCategories();
  }, []);

  function getAllContentCategories() {
    const headers = setHeaders();
    axios
      .get(`${HOST_NAME}/api/v1/${getCategoriesLink}${content.keyID}`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setContentCategories(res.data);
        }
      });
  }

  function editContentGroups(ctgIDList) {
    const headers = setHeaders();
    const newServiceCategories = {
      [field]: content.keyID,
      ctgIDList,
    };

    axios
      .put(`${HOST_NAME}/api/v1/${setCategoriesLink}`, newServiceCategories, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          getAllContentCategories();
        }
      });
  }

  function getAllParentIDs(data, nodeID) {
    const node = data.find((item) => item.keyID === nodeID);

    if (!node || node.fK_ParentID === null) {
      return node ? [node.keyID] : [];
    }
    return [...getAllParentIDs(data, node.fK_ParentID), node.keyID];
  }

  return (
    <Modal className="modal-grouping" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 2,
        }}
      >
        <div className="create-group-container">
          <div className="right ">
            <h3>گروه ها</h3>
            <CustomTreeMenu
              items={treeMenu}
              setSelectedGroup={setSelectedGroup}
            />
          </div>
          <div className="left ">
            <h2>{content.name}</h2>

            {selectedGroup && (
              <div>
                <h3 style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  انتخاب گروه
                </h3>
                <div className="choose-group">
                  <div>
                    <span>{selectedGroup.ctgName}</span>
                  </div>
                  <button
                    className="add-group-custom"
                    onClick={() => {
                      const allParentIDs = getAllParentIDs(
                        allData,
                        selectedGroup.keyID
                      );

                      editContentGroups([
                        ...allParentIDs,
                        ...contentCategories.map((category) => category.keyID),
                      ]);
                    }}
                  >
                    <MdAddToPhotos /> افزودن به لیست
                  </button>
                </div>
              </div>
            )}

            {contentCategories.length > 0 ? (
              <div className="group-list">
                <h3>لیست گروه های {content.name}</h3>
                {contentCategories.map((category) => (
                  <div key={category.keyID}>
                    <MdOutlineCancelPresentation
                      onClick={() =>
                        editContentGroups([
                          ...contentCategories
                            .filter(
                              (contentCategory) =>
                                contentCategory.keyID !== category.keyID
                            )
                            .map((contentCategory) => contentCategory.keyID),
                        ])
                      }
                    />

                    {category.ctgName}
                  </div>
                ))}
              </div>
            ) : (
              <span>بدون دسته بندی</span>
            )}
          </div>
        </div>
      </Paper>
    </Modal>
  );
}
