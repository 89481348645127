import React, { useState, useEffect } from "react";
import { Modal, Paper } from "@mui/material";
import axios from "axios";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import Swal from "sweetalert2";

import "./ModalExcer.css";

function ModalExcer({ isOpen, onClose, Idd, refresh, exerciseLimit }) {
  const [exercisesData, setExercisesData] = useState({
    name: "",
    subject1: "",
    subject2: "",
    preparation: "",
    execution: "",
  });

  useEffect(() => {
    if (Idd > 0) {
      const headers = setHeaders();
      axios
        .get(`${HOST_NAME}/api/v1/Exercise/${Idd}`, { headers })
        .then((res) => {
          res.status === 200 && setExercisesData(res.data);
        })
        .catch((error) => {
          console.error("خطا در درخواست به API: ", error);
        });
    } else {
      setExercisesData({
        name: "",
        subject1: "",
        subject2: "",
        preparation: "",
        execution: "",
      });
    }
  }, [Idd]);

  const updateUser = async () => {
    try {
      const headers = setHeaders();

      axios
        .put(
          `${HOST_NAME}/api/v1/Exercise`,
          {
            keyID: Idd,
            name: exercisesData.name,
            subject1: "نحوه اجرای تمرین",
            subject2: "نکات تمرین",
            preparation: exercisesData.preparation,
            execution: exercisesData.execution,
            otherDescription: exercisesData.execution,
            exercisesCategoryID: -1,
          },
          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            refresh();
            Swal.fire({
              icon: "success",
              title: "تمرین با موفقیت ویرایش شد",
            });
          }
        })
        .catch((err) => console.log("err", err));

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  const insertuser = async () => {
    try {
      const headers = setHeaders();

      axios
        .post(
          `${HOST_NAME}/api/v1/Exercise`,
          {
            keyID: -1,
            name: exercisesData.name,
            subject1: "نحوه اجرای تمرین",
            subject2: "نکات تمرین",
            preparation: exercisesData.preparation,
            execution: exercisesData.execution,
            otherDescription: exercisesData.execution,
            exercisesCategoryID: -1,
          },
          { headers }
        )
        .then((res) => {
          if (res.status === 201) {
            refresh();
            Swal.fire({
              icon: "success",
              title: "تمرین با موفقیت اضافه شد",
            });
          }
        })
        .catch((err) => {
          if (err.status === 403) {
            Swal.fire({
              icon: "error",
              title: `نمی توانید بیشتر از ${exerciseLimit} تمرین ایجاد کنید.`,
            });
          }
        });

      onClose();
    } catch (error) {
      console.error("خطا در ویرایش کاربر: ", error);
    }
  };

  return (
    <Modal className="modal-add-edit" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>{Idd > 0 ? "ویرایش تمرین" : "ایجاد تمرین"}</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          <form className="ModalExcer-form">
            <input
              type="text"
              placeholder="عنوان تمرین"
              value={exercisesData.name}
              onChange={(e) =>
                setExercisesData({ ...exercisesData, name: e.target.value })
              }
            />

            <textarea
              type="text"
              placeholder="نحوه اجرای تمرین"
              value={exercisesData.preparation}
              onChange={(e) =>
                setExercisesData({
                  ...exercisesData,
                  preparation: e.target.value,
                })
              }
              rows={6}
            />

            <textarea
              type="text"
              placeholder="نکات تمرین"
              value={exercisesData.execution}
              onChange={(e) =>
                setExercisesData({
                  ...exercisesData,
                  execution: e.target.value,
                })
              }
              rows={6}
            />
            <button onClick={Idd > 0 ? updateUser : insertuser}>
              {Idd > 0 ? "ویرایش تمرین" : "ایجاد تمرین"}
            </button>
          </form>
        </div>
      </Paper>
    </Modal>
  );
}

export default ModalExcer;
