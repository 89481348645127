import React, { useState, useEffect } from "react";
import "./Subscriptions.css";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import Footer from "../../Components/Footer/Footer";
import { Grid, Pagination } from "@mui/material";
import Subscription from "../../Components/Subscription/Subscription";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import { Stack } from "react-bootstrap";
import Loader from "../../Components/Loader/Loader";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function Subscriptions() {
  const [currentPage, setCurrentPage] = useState(1);
  const [subscriptionCountPage, setSubscriptionCountPage] = useState();
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const subscriptionsRate = [
    {
      id: 1,
      title: "اشتراک دو هفته‌ای (یکبار)",
      price: "رایگان",
      options: [
        "دسترسی به تمامی ویدئوهای آموزشی و تمرینی به مدت دو هفته",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم پیامینگ برای ارتباط با بیماران و ادمین",
        "فرصت ایده‌آل برای آشنایی با امکانات و خدمات سایت بدون هیچ هزینه‌ای",
      ],
      image: "/images/exercise1.webp",
    },
    {
      id: 2,
      title: "اشتراک سالانه پزشکان",
      price: "4,500,000",
      options: [
        "تمامی امکانات اشتراک ماهانه به همراه تخفیف ویژه سالانه",
        "دسترسی به ویدئوهای انحصاری و جدیدترین محتواهای آموزشی",
      ],
      image: "/images/exercise2.webp",
    },
    {
      id: 3,
      title: "اشتراک ماهانه پزشکان",
      price: "400,000",
      options: [
        "دسترسی به تمامی ویدئوهای آموزشی و تمرینی",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم پیامینگ برای ارتباط با بیماران و ادمین",
      ],
      image: "/images/exercise3.webp",
    },
    {
      id: 3,
      title: "اشتراک ماهانه پزشکان",
      price: "400,000",
      options: [
        "دسترسی به تمامی ویدئوهای آموزشی و تمرینی",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم پیامینگ برای ارتباط با بیماران و ادمین",
      ],
      image: "/images/exercise3.webp",
    },
    {
      id: 3,
      title: "اشتراک ماهانه پزشکان",
      price: "400,000",
      options: [
        "دسترسی به تمامی ویدئوهای آموزشی و تمرینی",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم پیامینگ برای ارتباط با بیماران و ادمین",
      ],
      image: "/images/exercise3.webp",
    },
    {
      id: 3,
      title: "اشتراک ماهانه پزشکان",
      price: "400,000",
      options: [
        "دسترسی به تمامی ویدئوهای آموزشی و تمرینی",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم پیامینگ برای ارتباط با بیماران و ادمین",
      ],
      image: "/images/exercise3.webp",
    },
    {
      id: 3,
      title: "اشتراک ماهانه پزشکان",
      price: "400,000",
      options: [
        "دسترسی به تمامی ویدئوهای آموزشی و تمرینی",
        "ایجاد و مدیریت پکیج‌های تمرینی برای بیماران",
        "استفاده از سیستم پیامینگ برای ارتباط با بیماران و ادمین",
      ],
      image: "/images/exercise3.webp",
    },
  ];

  useState(() => {
    getAllSubscriptions(1);
  }, []);

  function getAllSubscriptions(pageNumber) {
    setIsLoadingSubscriptions(true);

    const pageSize = 9;

    axios
      .get(
        `${HOST_NAME}/api/v1/Service/GetActives?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      .then((res) => {
        if (res.status === 200) {
          setAllSubscriptions(res.data.servicesList);
          setSubscriptionCountPage(Math.ceil(res.data.totalcount / pageSize));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setIsLoadingSubscriptions(false));
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <ResponsiveNavbar />

      <main className="subscriptions-main">
        <h1 className="home-header">اشتراک‌ها</h1>
        {!isLoadingSubscriptions ? (
          <Grid className="subscriptions-container" container spacing={4}>
            {allSubscriptions.map((subscription, index) => (
              <Grid key={subscription.keyID} item xs={12} sm={6} md={4} lg={3}>
                <Subscription {...subscription} listOrder={index + 1} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Loader />
        )}

        {!isLoadingSubscriptions ? (
          <MuiRTL>
            <Stack
              spacing={2}
              direction="rtl"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px auto",
                width: "fit-content",
              }}
            >
              <Pagination
                count={subscriptionCountPage}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
              />
            </Stack>
          </MuiRTL>
        ) : null}
      </main>
      <Footer />
    </>
  );
}
