import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  InputBase,
  Divider,
  IconButton,
  Grid,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SearchIcon from "@mui/icons-material/Search";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Dialog from "@mui/material/Dialog";
import Treemenu from "../../Treemenu/Treemenu";
import Pagination_ from "../../Pagination/Pagination";
import FormBox from "../../FormBox/FormBox ";

import { CardExersiceContext } from "./../../../Contexts/CardsExesciseContext";

import "./Collection_TabOne.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import { HOST_NAME } from "../../../config";
import { setHeaders } from "../../../utils";
import axios from "axios";

export default function Collection_TabOne() {
  const navigate = useNavigate();
  const [isShowTreemenu, setIsShowTreemenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [treeMenuDataExerciseCartData, setTreeMenuDataExerciseCartData] =
    useState([]);

  const {
    cardExersiesData,
    setCardExersiesData,
    keyIdItemsCardExersice,
    currentPage,
    setCurrentPage,
    setCountPageNation,
    isShowAlertError,
    setIsShowAlertError,
  } = useContext(CardExersiceContext);

  useEffect(() => {
    getAllTreeMenuDataExerciseCartDatas();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(currentPage);
    } else {
      getCardsExercisWithKeyIdItemsMenu(keyIdItemsCardExersice, currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchQuery) {
      return;
    } else {
      setCurrentPage(1);
      getCardsExercisWithKeyIdItemsMenu(keyIdItemsCardExersice, 1);
    }
  }, [keyIdItemsCardExersice]);

  useEffect(() => {
    if (treeMenuDataExerciseCartData.length === 0) {
      setIsShowAlertError(true);
    } else {
      setIsShowAlertError(false);
    }
  }, [treeMenuDataExerciseCartData]);

  useEffect(() => {
    setCurrentPage(1);
    handleSearch(1);
  }, [searchQuery]);

  function getAllTreeMenuDataExerciseCartDatas() {
    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/ExerciseCtg`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          const treeMenuDataWithShowAll = [
            {
              keyID: -1,
              ctgName: "نمایش همه",
              fK_ParentID: null,
            },
            ...res.data,
          ];

          setTreeMenuDataExerciseCartData(treeMenuDataWithShowAll);
        }
      })
      .catch((err) => console.log("err", err));
  }

  function getCardsExercisWithKeyIdItemsMenu(keyID, pagenumber) {
    const headers = setHeaders();

    const pageSize = 20;
    setIsLoading(true);
    const keyIDArray = Array.isArray(keyID) ? keyID : [keyID];
    const keyIDString = keyIDArray.join(" ");

    axios
      .get(
        `${HOST_NAME}/api/v1/Exercise/${
          keyIDString ? keyIDString : "-1"
        },${pagenumber},${pageSize}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setCardExersiesData(res.data.exerciseslist);
          setIsLoading(false);
          setCountPageNation(Math.ceil(res.data.totalcount / pageSize));
        }
      })
      .catch((e) => console.log(e));
  }

  function handleSearch(pageNumber) {
    if (searchQuery.length >= 1) {
      const headers = setHeaders();

      const pageSize = 8;
      setIsLoading(true);

      axios
        .get(
          `${HOST_NAME}/api/v1/Exercise/GetBYFilter?pageNumber=${pageNumber}&pageSize=${pageSize}&stringFilter=${searchQuery}`,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setCardExersiesData(res.data.exerciseslist);
            setIsLoading(false);
            setCountPageNation(Math.ceil(res.data.totalcount / pageSize));
          }
        })
        .catch((e) => console.log(e));
    } else {
      getCardsExercisWithKeyIdItemsMenu(keyIdItemsCardExersice, currentPage);
    }
  }

  return (
    <div className="container-fluid">
      <div className="col-12 main-section__tabOne">
        {isShowTreemenu && (
          <div className="col-3 section-treemenu-tab1">
            <Treemenu
              data={treeMenuDataExerciseCartData}
              pageUsed="exercises"
            />
          </div>
        )}

        {cardExersiesData ? (
          <Stack
            spacing={2}
            direction="rtl"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              overflowY: "hidden",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <PerfectScrollbar style={{ overflowX: "hidden" }}>
              <div style={{ width: "100%", margin: "1%" }}>
                <Paper
                  className="section-header-tab1"
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    font: "IRANSans",
                  }}
                >
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <div className="section-header-tab1-show-categry">
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      aria-label="directions"
                      onClick={() =>
                        setIsShowTreemenu((prevState) => !prevState)
                      }
                    >
                      <AccountTreeIcon fontSize="large" />
                    </IconButton>
                  </div>

                  <InputBase
                    sx={{ m: "0 10px", flex: 1, fontSize: 14 }}
                    placeholder="جست و جو ورزش ها ... "
                    inputProps={{ "aria-label": "search google maps" }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Paper>

                <div className="exercise-page-options">
                  <button
                    onClick={() => setIsOpenFilter(true)}
                    className="filter-btn"
                  >
                    <TuneOutlinedIcon /> فیلتر
                  </button>

                  <Dialog
                    className="filter-dialog"
                    open={isOpenFilter}
                    onClose={() => setIsOpenFilter(false)}
                  >
                    <Treemenu
                      data={treeMenuDataExerciseCartData}
                      pageUsed="exercises"
                    />
                  </Dialog>
                </div>

                {isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      margin: "1rem",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <div className="container section-cardExersice">
                      <div
                        className="row"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          gap: "16px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <FormBox
                            data={cardExersiesData}
                            isShowTreemenu={isShowTreemenu}
                            isShowAlertError={isShowAlertError}
                            isFromLibrary
                          />
                        </Grid>
                      </div>
                    </div>

                    <Pagination_ />
                  </>
                )}
              </div>
            </PerfectScrollbar>
          </Stack>
        ) : null}
      </div>
    </div>
  );
}
