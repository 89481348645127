import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { SubscriptionContext } from "../../Contexts/Subscriptions";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

export default function PaymentVerify() {
  const orderKey = localStorage.getItem("value");
  const { setChoosedSubscriptions } = useContext(SubscriptionContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [params] = useSearchParams();
  const [isFree, setIsFree] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);


  useEffect(() => {
    setIsSubmitting(true);
    const status = params.get("Status");
    if (status === "OK") {
      verifyPayment();
    } else if (status === "free") {
      setIsSubmitting(false);
      setIsFree(true);
    }
  }, []);

  function verifyPayment() {
    const headers = setHeaders();

    axios
      .post(
        `${HOST_NAME}/api/v1/Payment/verify?authority=${params.get(
          "Authority"
        )}&orderKeyID=${orderKey}`,
        null,
        {
          headers,
        }
      )
      .then((res) => {
        localStorage.setItem("user", JSON.stringify({ token: res.data.token }));
        setChoosedSubscriptions([]);

        axios
          .get(`${HOST_NAME}/api/v1/Order/${orderKey}`, {
            headers,
          })
          .then((result) => {
            if (result.status === 200) {
              setOrderInfo(result.data);
              setIsVerified(true);
              setIsSubmitting(false);
            } else {
              setIsVerified(false);
              setIsSubmitting(false);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsVerified(false);
            setIsSubmitting(false);
          });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  if (isSubmitting) {
    return <p>در حال پردازش...</p>;
  }

  return (
    <div className="payment-verify-container">
      <div className="order-info-container">
        {isFree ? (
          <>
            <img src="/images/order-info.webp" alt="ثبت موفقیت آمیز سفارش" />
            <h1 style={{ color: "rgb(0, 209, 139)" }}>
              اشتراک رایگان برای شما با موفقیت ثبت شد.
            </h1>
            <Link to="/">بازگشت به خانه</Link>
          </>
        ) : isVerified ? (
          <>
            <img src="/images/order-info.webp" alt="ثبت موفقیت آمیز سفارش" />
            <h1 style={{ color: "rgb(0, 209, 139)" }}>
              سفارش {orderInfo?.serviceName} با موفقیت ثبت شد.
            </h1>
            <h3>کد پیگیری: {orderInfo?.refId}</h3>
            <Link to="/">بازگشت به خانه</Link>
          </>
        ) : (
          <>
            <img src="/images/error-payment.jpg" alt="خطا در پرداخت" />
            <h1 style={{ color: "#EC0000" }}>پرداخت شما لغو شد</h1>
            <h3>پرداخت شما انجام نشد. لطفاً دوباره تلاش کنید.</h3>
            <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
              <Link to="/">بازگشت به خانه</Link>
              <Link to="/basket">بازگشت به سبد خرید</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
