import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import PropTypes from "prop-types";

import React, { useState, useContext, useEffect } from "react";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";

import {
  IconButton,
  Tabs,
  Tab,
  Grid,
  Dialog,
  Pagination,
  Stack,
} from "@mui/material";

import { Add } from "@mui/icons-material";

import { SelectedClientContext } from "../../Contexts/SelectedClientContext";

import { format } from "date-fns-jalali";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./Clients.css";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import ExerciseTable from "../../Components/TablePackagesClient/TablePackagesClient";
import "react-datepicker/dist/react-datepicker.css";

import { CustomTabPanel } from "../../Components/Tab/Tab";
import ClientRightSide from "../../Components/ClientRightSide/ClientRightSide";
import NewTicketModal from "../../Components/NewTicketModal/NewTicketModal";
import ChatBox from "../../Components/ChatBox/ChatBox";
import Loader from "../../Components/Loader/Loader";
import SingleTicketRow from "../../SingleTicketRow/SingleTicketRow";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

export default function Clients() {
  const [allPatientTickets, setAllPatientTickets] = useState(null);
  const [ticketsCountPage, setTicketsCountPage] = useState();
  const [ticketsCurrentPage, setTicketsCurrentPage] = useState(1);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [activeTicket, setActiveTicket] = useState();
  const [isNewTicketBoxOpen, setIsNewTicketBoxOpen] = useState(false);
  const [opponent, setopponent] = useState({
    userID: -1,
    fullname: "",
  });
  const [starterUser, setStarterUser] = useState({
    userID: -1,
    fullname: "",
  });

  const singleTicketRowProps = {
    setopponent,
    setStarterUser,
    setActiveTicket,
  };

  const chatBoxProps = {
    activeChat: activeTicket,
    starterUser,
    opponent,
  };

  const [tabValue, setTabValue] = useState(0);
  const [dataForShowInformation, setDataForShowInformation] = useState(null);

  const [clientsPackage, setClientsPackage] = useState([]);
  const [paintID, setPaintID] = useState("");
  const [isLodingGetPackageClient, setIsLodingGetPackageClient] =
    useState(false);

  const { selectedClient, setSelectedClient } = useContext(
    SelectedClientContext
  );

  const handlePageChange = (event, page) => {
    setTicketsCurrentPage(page);
  };


  useEffect(() => {
    if (dataForShowInformation) {
      getTicketsByPatientFilter(
        ticketsCurrentPage,
        dataForShowInformation.keyID
      );
    }
  }, [ticketsCurrentPage]);

  useEffect(() => {
   
    if (dataForShowInformation) {
      setPaintID(dataForShowInformation.keyID);
    }

    if (dataForShowInformation?.ticketAllowed) {
      getTicketsByPatientFilter(1, dataForShowInformation.keyID);
    } else {
      setAllPatientTickets(null);
    }
  }, [dataForShowInformation]);

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function getTicketsByPatientFilter(pageNumber, patientID) {
    setIsLoadingTickets(true);
    const headers = setHeaders();
    const pageSize = 8;

    axios
      .get(
        `${HOST_NAME}/api/v1/Patient/${patientID}/Tickets/${pageNumber},${pageSize}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setAllPatientTickets(res.data);
          setIsLoadingTickets(false);
          setTicketsCountPage(Math.ceil(res.data.totalcount / pageSize));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const clientRightSideProps = {
    setDataForShowInformation,
    dataForShowInformation,
    setSelectedClient,
    setIsLodingGetPackageClient,
    setClientsPackage,
  };

  return (
    <>
      <ResponsiveNavbar />

      <MuiRTL>
        <div
          className="client-mainSection"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <ClientRightSide {...clientRightSideProps} />

          <Box className="client-tabs">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  iconPosition="start"
                  label="پکیج های بیمار"
                  {...a11yProps(0)}
                />
                {allPatientTickets && (
                  <Tab
                    iconPosition="start"
                    label="مدیریت پیام ها"
                    {...a11yProps(1)}
                  />
                )}
              </Tabs>
            </Box>

            {clientsPackage.length > 0 && (
              <CustomTabPanel value={tabValue} index={0}>
                <div style={{ flex: "4", height: "100vh" }}>
                  {isLodingGetPackageClient ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        margin: "2.5rem",
                      }}
                    >
                      <CircularProgress />
                      <div>
                        <p
                          style={{
                            fontFamily: "IRANSans",
                            margin: "1.5rem",
                            fontSize: "1.2rem",
                          }}
                        >
                          لطفا منتظر بمانید ...
                        </p>
                      </div>
                    </Box>
                  ) : (
                    <div
                      className="right-side__mainSection"
                      style={{ display: "flex" }}
                    >
                      <ExerciseTable
                        packagesMade={clientsPackage}
                        paintID={paintID}
                      />
                    </div>
                  )}
                </div>
              </CustomTabPanel>
            )}

            {allPatientTickets && (
              <CustomTabPanel
                style={{ minHeight: "100vh", backgroundColor: "#fff" }}
                value={tabValue}
                index={1}
              >
                <Grid
                  container
                  alignItems="stretch"
                  style={{ padding: "2rem", gap: "1rem" }}
                >
                  <Grid item xs={5} sm={3} style={{ maxWidth: "unset" }}>
                    <button
                      onClick={() => setIsNewTicketBoxOpen(true)}
                      className="client-add-ticket"
                    >
                      <IconButton>
                        <Add fontSize="large" style={{ color: "white" }} />
                      </IconButton>
                      پیام جدید
                    </button>
                  </Grid>
                  <Grid item xs={5} sm={3} style={{ maxWidth: "unset" }}>
                    <div className="tickets-count">
                      <ConfirmationNumberOutlinedIcon />
                      <div>
                        <span className="ticket-count-title">همه پیام ها</span>
                        <span>{allPatientTickets?.totalcount} عدد</span>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <div className="client-ticket-table-container">
                  {!isLoadingTickets ? (
                    <table className="client-tickets-table">
                      <thead>
                        <tr>
                          {/* <th>فرستنده</th>
                        <th>گیرنده</th> */}
                          <th>موضوع</th>
                          <th>تاریخ ایجاد</th>
                          <th>تاریخ آپدیت</th>
                          <th>وضعیت</th>
                          {/* <th>حذف</th> */}
                        </tr>
                      </thead>
                      {allPatientTickets && (
                        <tbody>
                          {allPatientTickets.ticketslist?.map((ticket) => (
                            <SingleTicketRow
                              isFromClient
                              key={ticket.keyID}
                              {...ticket}
                              {...singleTicketRowProps}
                            />
                          ))}
                        </tbody>
                      )}
                    </table>
                  ) : (
                    <Loader />
                  )}
                </div>

                {!isLoadingTickets ? (
                  <MuiRTL>
                    <Stack
                      spacing={2}
                      direction="rtl"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px auto",
                        width: "fit-content",
                      }}
                    >
                      <Pagination
                        count={ticketsCountPage}
                        color="primary"
                        page={ticketsCurrentPage}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </MuiRTL>
                ) : null}
              </CustomTabPanel>
            )}
          </Box>
        </div>

        <Dialog
          className="client-chat-box-dialog"
          open={activeTicket}
          onClose={() => setActiveTicket(null)}
        >
          <ChatBox {...chatBoxProps} isModal />
        </Dialog>

        <NewTicketModal
          isNewTicketBoxOpen={isNewTicketBoxOpen}
          setIsNewTicketBoxOpen={setIsNewTicketBoxOpen}
          receiverID={dataForShowInformation?.userid}
          getAllTickets={() =>
            getTicketsByPatientFilter(1, dataForShowInformation?.keyID)
          }
        />
      </MuiRTL>
    </>
  );
}
