import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";
import Swal from "sweetalert2";

import "./ManageExercise.css";

const MAX_FILE_SIZE = 20 * 1024 * 1024; 

const MultiImageUploader = ({ images, setImages }) => {
  const [loading, setLoading] = useState(false);

  const validateFile = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");

    if (!isImage && !isVideo) {
      Swal.fire({
        icon: "error",
        text: "فقط فرمت‌های عکس و ویدیو مجاز هستند.",
      });
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      Swal.fire({
        icon: "error",
        text: "حجم فایل نباید بیشتر از 20 مگابایت باشد.",
      });
      return false;
    }

    return true;
  };

  const convertToWebP = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          canvas.toBlob(
            async (blob) => {
              const arrayBuffer = await blob.arrayBuffer();
              const fileBytes = new Uint8Array(arrayBuffer);

              resolve({
                fileName: `image-${Date.now()}.webp`,
                fileContent: Array.from(fileBytes).join(","),
                fileTitle: "title",
                fileDescription: "description",
                filesAddress: "",
                keyID: 0,
                fileType: "webp",
                isImage: true,
                preview: URL.createObjectURL(blob),
              });
            },
            "image/webp",
            0.8 
          );
        };
      };
      reader.readAsDataURL(file);
    });

  const convertToFileData = (file) =>
    new Promise((resolve) => {
      if (file.type.startsWith("image/")) {
        resolve(convertToWebP(file));
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const fileBytes = new Uint8Array(reader.result);
          const fileType = file.name.split(".").pop();

          resolve({
            fileName: file.name,
            fileContent: Array.from(fileBytes).join(","),
            fileTitle: "title",
            fileDescription: "description",
            filesAddress: "",
            keyID: 0,
            fileType: fileType,
            isImage: false,
            preview: URL.createObjectURL(file),
          });
        };
        reader.readAsArrayBuffer(file);
      }
    });

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(validateFile);

    if (validFiles.length === 0) return;

    setLoading(true);

    const formattedFiles = await Promise.all(
      validFiles.map((file) => convertToFileData(file))
    );

    setImages((prev) => [...prev, ...formattedFiles]);
    setLoading(false);
  };

  const handleRemoveImage = (fileName) => {
    setImages((prev) => prev.filter((img) => img.fileName !== fileName));
  };

  return (
    <div className="multi-image-uploader">
      <input
        type="file"
        multiple
        accept="image/*,video/*"
        onChange={handleFileChange}
        className="mb-4"
      />
      {loading && <p>در حال پردازش فایل‌ها...</p>}
      <div className="image-previews">
        {images.map((img) => (
          <div
            key={img.fileName}
            className="image-preview"
            style={{ position: "relative" }}
          >
            {img.isImage ? (
              <img src={img.preview} alt={img.fileName} width="200" />
            ) : (
              <video src={img.preview} controls width="200" />
            )}
            <p>{img.fileName}</p>
            <button
              onClick={() => handleRemoveImage(img.fileName)}
              className="remove-btn"
            >
              <TiDelete className="icon" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiImageUploader;
