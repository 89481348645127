import React, { useState, useEffect } from "react";
import ImageAvatars from "../ImageAvatar/ImageAvatar";
import { Button, Grid, TextField } from "@mui/material";
import VideoModal from "../VideoModal/VideoModal";
import { FormatExecutionString, setHeaders } from "../../utils";
import "./CaptionFromCardClient.css";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function CaptionFormCardClient({
  packageID,
  keyID,
  execution,
  otherDescription,
  preparation,
  subject1,
  subject2,
  name,
  mainPicturePath,
  exercise_Files,
}) {

  const [isMediaOpen, setIsMediaOpen] = useState(false);
  const [exerciseParams, setExerciseParams] = useState([]);
  const [exerciseDetails, setExerciseDetails] = useState(
    `<div>
          <span>${subject1}</span>
          <ul>${FormatExecutionString(preparation)}</ul>
          <span>${subject2}</span>
          <ul>${FormatExecutionString(execution)}</ul>
          
        </div>`
  );

  useEffect(() => {
    getParameters();
  }, []);

  function getParameters() {
    const headers = setHeaders();

    axios
      .get(
        `${HOST_NAME}/api/v1/Package/${packageID}/Exercises/${keyID}/Parameters`,
        {
          headers,
        }
      )
      .then((res) => res.status === 200 && setExerciseParams(res.data))
      .catch((err) => console.log("err", err));
  }

  return (
    <div className="caption-card row">
      {/* section 1 */}
      <div className="section-header-caption-cardExersice">
        <div className="avtar-header-caption-cardExersice">
          <ImageAvatars fontSize="large" imgSrc={mainPicturePath} />
          <p
            style={{
              fontFamily: "IRANSans",
              padding: "5px",
              fontSize: "1.3rem",
            }}
          >
            {name}
          </p>
        </div>
      </div>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "2rem" }}
      >
        {exerciseParams.map((param) => (
          <>
            <Grid item xs={6}>
              <TextField
                style={{ fontFamily: "IRANSans" }}
                label="عنوان"
                variant="filled"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                value={param.paramName}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                style={{ fontFamily: "IRANSans" }}
                label="مقدار"
                variant="filled"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                value={param.paramValue}
              />
            </Grid>
          </>
        ))}
      </Grid>

      <div
        className="client-exercise-details"
        dangerouslySetInnerHTML={{
          __html: exerciseDetails,
        }}
      ></div>

      <div className="container-fluid section-video-image-caption-cardExersice">
        <div>
          <Button
            className="show-video-btn"
            onClick={() => setIsMediaOpen(true)}
          >
            نمایش تصاویر و ویدیو ها
          </Button>

          {exercise_Files.length > 0 ? (
            <VideoModal
              isOpen={isMediaOpen}
              handleClose={() => setIsMediaOpen(false)}
              media={exercise_Files}
            />
          ) : (
            <p style={{ color: "red", fontFamily: "IRANSans" }}>
              هیچ تصویر و ویدویی برای نمایش وجود ندارد .
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
