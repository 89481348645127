import React, { useEffect, useState } from "react";
import "./SingleGallery.css";
import Footer from "../../Components/Footer/Footer";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HOST_NAME } from "../../config";
import { formatDate } from "../../utils";
import { Box, CircularProgress } from "@mui/material";
import { createPortal } from "react-dom";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

import CustomModal from "../../Components/CustomModal/CustomModal";

export default function SingleGallery() {
  const { galleryID } = useParams();
  const [galleryData, setGalleryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageExpanded, setIsImageExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${HOST_NAME}/api/v1/Gallery/${galleryID}`)
      .then((res) => {
        if (res.status === 200) {
          setGalleryData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "1rem",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <ResponsiveNavbar />
      {galleryData && (
        <>
          <div className="main-img-container">
            <img src={galleryData.mainPicPath} alt="gallery" />
          </div>

          <div className="content">
            <h1>{galleryData.title}</h1>
            <p>{galleryData.description}</p>
            <span>تاریخ رویداد: {formatDate(galleryData.eventDate)}</span>
            <div className="photos">
              {galleryData.filelist
                .filter((file) => file.fileDescription !== "main")
                .map((image, index) => (
                  <img
                    onClick={() => {
                      setIsImageExpanded(true);
                      setSelectedIndex(index);
                    }}
                    key={image}
                    src={image.fullpath}
                    alt="slider"
                  />
                ))}
            </div>
          </div>
        </>
      )}
      <Footer />

      {isImageExpanded &&
        createPortal(
          <CustomModal setIsOpen={setIsImageExpanded}>
            <Swiper
              initialSlide={selectedIndex}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {galleryData.filelist
                .filter((file) => file.fileDescription !== "main")
                .map((image) => (
                  <SwiperSlide>
                    <img key={image} src={image.fullpath} alt="slider" />
                  </SwiperSlide>
                ))}
            </Swiper>
          </CustomModal>,
          document.getElementById("modal")
        )}
    </>
  );
}
