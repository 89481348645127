import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";

import { PackagesMadeContext } from "../../../Contexts/PackagesMade";
import Swal from "sweetalert2";
import { isUserAdmin, parseJwt, setHeaders } from "../../../utils";
import { PackageID } from "../../../Contexts/PackageID";
import { HOST_NAME } from "../../../config";
import axios from "axios";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ModalIconSave({
  coverContent,
  setIsTabPanelOpen,
  isEditingTabPanel,
}) {
  const [open, setOpen] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const { templateID, setTemplateID, packageID } = useContext(PackageID);
  const { packagesMade, setPackagesMade, exerciseParameters } =
    useContext(PackagesMadeContext);

  function sendParametersToTemplate(id) {
    const headers = setHeaders();

    const deviders = packagesMade.exercises
      ?.map((card, index) => ({
        keyID: 0,
        fK_TemplateID: id,
        title: card.contentTitle || card.title || "",
        instruction: card.contentDescription || card.instruction || "",
        rowIndex: index,
        type: card.type,
      }))
      .filter((devider) => devider.type === "devider");

    const editingDeviders = packagesMade.exercises
      .map((card, index) => ({
        keyID: card.keyID,
        fK_TemplateID: card.fK_TemplateID,
        title: card.title || "",
        instruction: card.instruction || "",
        rowIndex: index,
        type: card.type,
      }))
      ?.filter((devider) => devider.fK_TemplateID);

    if (templateID === -1) {
      const postDeviderRequests = [...deviders, ...editingDeviders].map(
        (devider) =>
          axios
            .post(`${HOST_NAME}/api/v1/Template/PostDevider`, devider, {
              headers,
            })
            .catch((err) => console.log("err", err))
      );

      Promise.all(postDeviderRequests)
        .then((results) => {
          setTemplateID(-1);
        })
        .catch((error) => {
          console.error("Error in post devider requests:", error);
        });
    } else {
      const putDevidersRequests = editingDeviders.map((devider) =>
        axios
          .put(`${HOST_NAME}/api/v1/Template/PutDevider`, devider, {
            headers,
          })
          .catch((err) => console.log("err", err))
      );

      const postDeviderRequests = deviders.map((devider) =>
        axios
          .post(`${HOST_NAME}/api/v1/Template/PostDevider`, devider, {
            headers,
          })
          .catch((err) => console.log("err", err))
      );

      Promise.all([...putDevidersRequests, ...postDeviderRequests])
        .then((results) => {
          setTemplateID(-1);
        })
        .catch((error) => {
          console.error("Error in post devider requests:", error);
        });
    }

    const postExerciseParamsRequest = exerciseParameters.map((exercise) =>
      axios
        .post(
          `${HOST_NAME}/api/v1/Template/${id}/Exercises/${exercise.exerciseID}/Parameters`,
          exercise.params.map(
            ({ keyID, paramName, paramValue, paramType, isDefault }) => ({
              keyID,
              paramName,
              paramValue,
              paramType,
              isDefault,
            })
          ),
          {
            headers,
          }
        )
        .catch((err) => console.log("err", err))
    );

    Promise.all(postExerciseParamsRequest)
      .then((res) => {
        setTemplateID(-1);
      })
      .catch((error) => {
        console.error("Error in exercise parameter requests:", error);
      });
  }

  const handleSavePackage = () => {
    const newPackage = {
      ctgID: -1,
      exercisekeylist: packagesMade.exercises
        ?.map((card, index) => ({
          exerciseID: card.keyID,
          status: index,
          isCustomized: card.isCustom || card.isCustomized,
          type: card.type,
          fK_TemplateID: card.fK_TemplateID,
        }))
        .filter((card) => card.type !== "devider" && !card.fK_TemplateID),

      educationkeyList: packagesMade.educations?.map(
        (education) => education.keyID
      ),
      pkgDto: {
        keyID: templateID,
        packageName: coverContent.contentTitle || "بدون عنوان",
        packageDescription: coverContent.contentDescription || "",
      },
    };

    const headers = setHeaders();
    axios
      .post(`${HOST_NAME}/api/v1/Template`, newPackage, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: `الگو با موفقیت ${
              templateID !== -1 ? "ویرایش" : "ساخته"
            } شد`,
            icon: "success",
          });

          handleClose();
          setIsTabPanelOpen(false);
          sendParametersToTemplate(res.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const headers = setHeaders();
    axios
      .get(
        `${HOST_NAME}/api/v1/Template?isSite=${isUserAdmin([
          "superadmin",
          "mainadmin",
        ])}`,
        {
          headers,
        }
      )
      .then((res) => {
        res.status === 200 && setAllTemplates(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  function checkCoverContent() {
    if (coverContent.contentTitle === "" || !Object.keys(coverContent).length) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "از قسمت کاور نام پکیج خود را وارد کنید.",
      });
    } else if (
      allTemplates.find(
        (template) => template.packageName === coverContent.contentTitle
      )
    ) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "نام پکیح وارد شده تکراری است.",
      });
    } else if (
      !packagesMade.educations.length &&
      !packagesMade.exercises.length
    ) {
      Swal.fire({
        title: "خطا",
        icon: "error",
        text: "حداق یک تمرین یا آموزش به پکیج اضافه کنید",
      });
    } else {
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        onClick={isEditingTabPanel ? handleSavePackage : checkCoverContent}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconButton>
          <SaveIcon fontSize="large" />
        </IconButton>

        <p style={{ fontSize: "1.2rem" }}>
          {isEditingTabPanel ? "ویرایش" : "ذخیره"}
        </p>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            cursor: "move",
            color: "#1876d3",
            fontSize: "1.5rem",
            fontFamily: "IRANSans",
          }}
          id="draggable-dialog-title"
        >
          ذخیره ورزش
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "1.3rem", fontFamily: "IRANSans" }}
          >
            آیا می خواهید این پکیج را به عنوان یک الگو ذخیره کنید؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            انصراف
          </Button>
          <Button
            onClick={handleSavePackage}
            style={{ backgroundColor: "#1876d3" }}
          >
            <p style={{ color: "white" }}>بله</p>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
