import React, { useState, useEffect } from "react";
import {
  formatDate,
  FormatExecutionString,
  formatTime,
  setHeaders,
} from "../../utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
  Avatar,
} from "@mui/material";
import {
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import SwitcheButton from "../SwitchButton/SwitchButton";
import "./styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CaptionFormCardClient from "../CaptionFormCard3/CaptionFormCardClient";
import ListItemEducationIndex from "../LeftSide/ListItemsEducationIndex/ListItemEducationIndex";
import { HOST_NAME } from "../../config";
import axios from "axios";

const Row = ({ packageInfo, packagesMade, paintID }) => {
  const [open, setOpen] = useState(false);
  const [packageDetail, setPackageDetail] = useState({});
  const [packageIDSelected, setPackageIDSelected] = useState("");
  const [isLoadingPackage, setIsLoadingPackage] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [packageExercises, setPackageExercises] = useState([]);

  function getPackageExercises(headers) {
    axios
      .get(`${HOST_NAME}/api/v1/Package/${packageInfo.keyID}/Exercises`, {
        headers,
      })
      .then((res) => {
        res.status === 200 && getPackageDeviders(headers, res.data);
      })
      .catch((e) => console.log(e));
  }

  function getPackageDeviders(headers, exercises) {
    axios
      .get(
        `${HOST_NAME}/api/v1/Package/GetPackageDeviders?packageId=${packageInfo.keyID}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          res.data.map((devider) => [
            ...exercises.splice(devider.rowIndex, 0, devider),
          ]);

          setPackageExercises(exercises);
          setIsLoadingPackage(false);
        }
      })
      .catch((e) => console.log(e));
  }

  const getPackageSClient = (id) => {
    setIsLoadingPackage(true);
    if (packageInfo) {
      const headers = setHeaders();
      axios
        .get(`${HOST_NAME}/api/v1/Package/${id}`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setPackageDetail(res.data);
            getPackageExercises(headers);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleExpandClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  return (
    <>
      <TableRow
        onClick={() => {
          getPackageSClient(packageInfo.keyID);
          setPackageIDSelected(packageInfo.keyID);
        }}
      >
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen((prevState) => !prevState)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {packageInfo.packageName}
        </TableCell>
        {/* <TableCell component="th" scope="row">
            Last Visit
        </TableCell> */}
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {formatTime(packageInfo.startDate)} |{" "}
          {formatDate(packageInfo.startDate)}
        </TableCell>
        <TableCell>
          <SwitcheButton
            paintID={paintID}
            packageIDSelected={packageInfo.keyID}
            stausShow={packageInfo.isActive}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="details">
              <TableBody>
                <TableRow>
                  {packageDetail ? (
                    <>
                      {isLoadingPackage ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            margin: "1.5rem",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <p
                            style={{
                              margin: "1.5rem",
                              color: "#1876d3",
                              fontFamily: "IRANSans",
                              fontSize: "1.7rem",
                            }}
                          >
                            نام پکیج: {packageInfo.packageName}
                          </p>
                          <p
                            style={{
                              margin: "1.5rem",
                              fontFamily: "IRANSans",
                              fontSize: "1.2rem",
                            }}
                          >
                            توضیحات: {packageInfo.packageDescription}
                          </p>

                          {packageExercises.length > 0 ? (
                            <TableContainer component={Paper}>
                              <Table aria-label="collapsible table">
                                <TableBody>
                                  {packageExercises.map((exercise, index) => (
                                    <>
                                      <TableRow className="simple-card">
                                        {!exercise.fK_PackageID ? (
                                          <>
                                            <TableCell>
                                              <Avatar
                                                alt={`Avatar ${exercise.keyID}`}
                                                src={exercise.mainPicturePath}
                                              />
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                fontFamily: "IRANSans",
                                              }}
                                            >
                                              {exercise.name}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="expand"
                                                onClick={() => {
                                                  handleExpandClick(index);
                                                }}
                                              >
                                                <ExpandMore />
                                              </IconButton>
                                            </TableCell>{" "}
                                          </>
                                        ) : (
                                          <TableCell colSpan={3}>
                                            <p className="devider-title">
                                              {exercise.title}
                                            </p>
                                            <p className="devider-description">
                                              {exercise.instruction}
                                            </p>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                          }}
                                          colSpan={6}
                                        >
                                          <Collapse
                                            in={expandedRow === index}
                                            timeout="auto"
                                            unmountOnExit
                                            style={{
                                              padding: "5px",
                                            }}
                                          >
                                            <CaptionFormCardClient
                                              packageID={packageDetail.keyID}
                                              {...exercise}
                                            />
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : null}

                          {packageDetail.educations
                            ? packageDetail.educations.map((education) => (
                                <ListItemEducationIndex
                                  key={education.keyID}
                                  {...education}
                                  isForClient
                                />
                              ))
                            : null}
                        </>
                      )}
                    </>
                  ) : null}
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ExerciseTable = ({ packagesMade, paintID }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="exercise table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.1rem",
                color: "#1876d3",
              }}
            >
              پکیج های ست شده
            </TableCell>
            <TableCell
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.1rem",
                color: "#1876d3",
              }}
            >
              تاریخ شروع{" "}
            </TableCell>
            {/* <TableCell style={{ fontFamily : 'IRANSans', fontSize : '1.1rem' , color: '#1876d3'}}>تاریخ اعتبار</TableCell>*/}
            <TableCell
              style={{
                fontFamily: "IRANSans",
                fontSize: "1.1rem",
                color: "#1876d3",
              }}
            >
              نمایش در پورتال{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packagesMade.map((pk) => (
            <Row
              key={pk.keyID}
              packageInfo={pk}
              packagesMade={packagesMade}
              paintID={paintID}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AccordionItem = (card) => {
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setIsMoreDetailsOpen((prevState) => !prevState)}
          >
            {isMoreDetailsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Avatar>{card.mainPicturePath && card.mainPicturePath}</Avatar>
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>{card.name}</TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {card.subject1}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {/* {card.preparation} */}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {card.subject2}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {/* {card.execution} */}
        </TableCell>
        <TableCell style={{ fontFamily: "IRANSans" }}>
          {/* <Button
      onClick={() => handleOpenModal(row)}
    >
      جزییات بیشتر
    </Button> */}
        </TableCell>
      </TableRow>
      {isMoreDetailsOpen && (
        <TableRow>
          <TableCell colSpan={12}>
            <Collapse in={isMoreDetailsOpen} timeout="auto" unmountOnExit>
              {card.preparation && card.execution ? (
                <div className="more-details">
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: FormatExecutionString(card.preparation),
                    }}
                  ></ul>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: FormatExecutionString(card.execution),
                    }}
                  ></ul>
                </div>
              ) : (
                <p className="no-more-details">
                  جزییاتی برای این تمرین وجود ندارد
                </p>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ExerciseTable;
