import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import MuiRTL from "../MuiRTL/MuiRTL";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import { BiSolidMessageRoundedError, BiSolidPhoneCall } from "react-icons/bi";
import { RiQuillPenFill, RiLogoutCircleLine } from "react-icons/ri";
import { HiTemplate } from "react-icons/hi";
import { FaAppleAlt, FaHome, FaRegLightbulb } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SettingsButton from "../SettingsIconNavbar";
import { IoChevronDownOutline } from "react-icons/io5";

import "./ResponsiveNavbar.css";

import {
  isAuthenticated,
  isUserAdmin,
  isUserRoleActive,
  isUserRoleNewUser,
  parseJwt,
  setHeaders,
} from "../../utils";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import Swal from "sweetalert2";
import { CardExersiceContext } from "../../Contexts/CardsExesciseContext";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import { SubscriptionContext } from "../../Contexts/Subscriptions";
import { HOST_NAME } from "../../config";
import axios from "axios";

const ResponsiveNavbar = () => {
  const [username, setUsername] = useState("");
  const { setIsTabPanelOpen } = useContext(PackagesMadeContext);
  const { setKeyIdItemsCardExersice, setKeyIdItemsEducation } =
    useContext(CardExersiceContext);

  const {
    choosedSubscriptions,
    setChoosedSubscriptions,
    setHasActiveOrder,
    hasActiveOrder,
  } = useContext(SubscriptionContext);

  const navigate = useNavigate();
  const [userValidationState, setUserValidationState] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const moreSectionLinks = [
    {
      label: "درباره ما",
      to: "/about",
      icon: (
        <SettingsButton
          icon={<BiSolidMessageRoundedError style={{ color: "lightblue" }} />}
        />
      ),
    },
    {
      label: "تماس با ما",
      to: "/contact",
      icon: (
        <SettingsButton
          icon={<BiSolidPhoneCall style={{ color: "lightblue" }} />}
        />
      ),
    },
    {
      label: "گالری",
      to: "/gallery",
      icon: (
        <SettingsButton
          icon={<PhotoLibraryIcon style={{ color: "lightblue" }} />}
        />
      ),
    },
  ];

  const baseLinks = [
    {
      label: "خانه",
      to: "/",
      icon: <SettingsButton icon={<FaHome style={{ color: "lightblue" }} />} />,
    },
    ...moreSectionLinks,
  ];

  const commonLinks = [
    {
      label: "خانه",
      to: "/",
      icon: <SettingsButton icon={<FaHome style={{ color: "lightblue" }} />} />,
    },
    {
      label: "ساخت برنامه",
      to: "/build",
      icon: (
        <SettingsButton
          icon={<RiQuillPenFill style={{ color: "lightblue" }} />}
        />
      ),
    },
    {
      label: "قالب های آماده",
      to: "/templates",
      icon: (
        <SettingsButton icon={<HiTemplate style={{ color: "lightblue" }} />} />
      ),
    },
    {
      label: "آموزش ها",
      to: "/education",
      icon: (
        <SettingsButton icon={<FaAppleAlt style={{ color: "lightblue" }} />} />
      ),
    },
  ];

  const manageSectionLinks = [
    {
      label: "پیام‌ها",
      to: "/ticketing",
      icon: (
        <SettingsButton
          icon={<ConfirmationNumberIcon style={{ color: "lightblue" }} />}
        />
      ),
    },
    {
      label: "تمرین های من",
      to: "/exercise-management",
      icon: (
        <SettingsButton
          icon={<FaRegLightbulb style={{ color: "lightblue" }} />}
        />
      ),
    },
    {
      label: "آموزش های من",
      to: "/education-management",
      icon: (
        <SettingsButton
          icon={<CastForEducationIcon style={{ color: "lightblue" }} />}
        />
      ),
    },
  ];

  let menuItems = [];

  if (isAuthenticated() && isUserAdmin("Admin")) {
    menuItems = [
      ...commonLinks,
      {
        label: "بیماران",
        to: "/clients",
        icon: (
          <SettingsButton
            icon={<BsFillPeopleFill style={{ color: "lightblue" }} />}
          />
        ),
      },
    ];
  } else if (isAuthenticated() && isUserAdmin(["superadmin", "mainadmin"])) {
    menuItems = [...commonLinks, ...moreSectionLinks];
  } else {
    menuItems = baseLinks;
  }

  useEffect(() => {
    if (isAuthenticated() && isUserAdmin(["Admin"])) {
      const headers = setHeaders();
      axios
        .get(`${HOST_NAME}/api/v1/Physician/GetLoginedInfoByuserID`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setHasActiveOrder(res.data.hasActiveOrder);
            axios
              .get(
                `${HOST_NAME}/api/v1/Physician/${res.data.keyID}/PhysicianFiles`,
                {
                  headers,
                }
              )
              .then((result) => {
                result.status === 200 &&
                  setUserValidationState(result.data.result);
              })
              .catch((err) => console.log("err", err));
          }
        })
        .catch((err) => console.log("err", err));
    }
    if (
      window.location.pathname !== "/profile" &&
      isAuthenticated() &&
      isUserRoleNewUser()
    ) {
      navigate("/profile");
    }
  }, []);

  function handleLogOut() {
    Swal.fire({
      title: "آیا مطمئن هستید؟",
      text: "آیا می‌خواهید از برنامه خارج شوید؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        navigate("/login");
        setIsTabPanelOpen(false);
        setKeyIdItemsCardExersice([]);
        setKeyIdItemsEducation([]);
        setChoosedSubscriptions([]);
      }
    });
  }

  return (
    <div>
      <AppBar position="fixed" style={{ height: "60px" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            onClick={toggleMenu}
            className="menu-icon"
          >
            <MenuIcon />
          </IconButton>
          <div className="main-header__right">
            <ul className="main-header__menu">
              {menuItems.map((item, index) => (
                <li key={index} className="main-header__item">
                  <NavLink to={item.to} className="main-header__link">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        className="link-text"
                        style={{ marginInline: "0.3rem", color: "lightblue" }}
                      >
                        {item.icon}
                      </span>
                      <span
                        className="link-text"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {item.label}
                      </span>
                    </div>
                  </NavLink>
                </li>
              ))}

              {isAuthenticated() && isUserAdmin(["Admin"]) && (
                <>
                  <li className="nested-parent main-header__item">
                    <span
                      className="link-text"
                      style={{ marginInline: "0.3rem", color: "lightblue" }}
                    >
                      <SettingsIcon />
                    </span>
                    <span className="link-text" style={{ fontSize: "1.5rem" }}>
                      مدیریت
                    </span>

                    <span
                      className="link-text"
                      style={{
                        marginInline: "0.3rem",
                        color: "lightblue",
                        marginTop: ".5rem",
                      }}
                    >
                      <IoChevronDownOutline />
                    </span>

                    <ul className="nested-links">
                      {manageSectionLinks.map((item, index) => (
                        <li key={index}>
                          <NavLink to={item.to} className="main-header__link">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                className="link-text"
                                style={{
                                  marginInline: "0.3rem",
                                  color: "lightblue",
                                }}
                              >
                                {item.icon}
                              </span>
                              <span
                                className="link-text"
                                style={{ fontSize: "1.5rem" }}
                              >
                                {item.label}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="nested-parent main-header__item">
                    <span
                      className="link-text"
                      style={{ marginInline: "0.3rem", color: "lightblue" }}
                    >
                      <MoreVertIcon />
                    </span>
                    <span className="link-text" style={{ fontSize: "1.5rem" }}>
                      بیشتر
                    </span>
                    <span
                      className="link-text"
                      style={{
                        marginInline: "0.3rem",
                        color: "lightblue",
                        marginTop: ".5rem",
                      }}
                    >
                      <IoChevronDownOutline />
                    </span>
                    <ul className="nested-links">
                      {moreSectionLinks.map((item, index) => (
                        <li key={index}>
                          <NavLink to={item.to} className="main-header__link">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                className="link-text"
                                style={{
                                  marginInline: "0.3rem",
                                  color: "lightblue",
                                }}
                              >
                                {item.icon}
                              </span>
                              <span
                                className="link-text"
                                style={{ fontSize: "1.5rem" }}
                              >
                                {item.label}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>

          <div className="main-header__left">
            <ul className="main-header__menu">
              {isAuthenticated() && !isUserRoleNewUser() && (
                <NavLink to="/basket" className="basket-btn">
                  <LocalMallIcon
                    style={{
                      color: "#fff",
                      marginLeft: "1rem",
                      marginTop: "1rem",
                      fontSize: "2rem",
                    }}
                  />
                  {choosedSubscriptions.length > 0 && (
                    <span className="count">{choosedSubscriptions.length}</span>
                  )}
                </NavLink>
              )}

              {isAuthenticated() && isUserRoleNewUser() && (
                <Link to="/profile" className="profile-btn">
                  <AccountCircleIcon />
                  {userValidationState.length ? (
                    <span className="validation-true">در انتظار تایید</span>
                  ) : (
                    <span className="validation-false">!</span>
                  )}
                </Link>
              )}

              {isAuthenticated() ? (
                <>
                  <li
                    className="main-header__item login-register-btns"
                    onClick={handleLogOut}
                  >
                    <RiLogoutCircleLine
                      style={{ color: "#fff", marginLeft: ".2rem" }}
                    />
                    <NavLink to="#" className="main-header__link">
                      <span className="link-text">خروج</span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <div className="login-register-btns">
                  <li className="main-header__item">
                    <NavLink className="main-header__link" to="/register">
                      ثبت نام
                    </NavLink>
                  </li>
                  <span>/</span>
                  <li className="main-header__item">
                    <NavLink className="main-header__link" to="/login">
                      ورود
                    </NavLink>
                  </li>
                </div>
              )}

              <li>
                <img src="/images/bettermove.png" alt="logo" />
              </li>
            </ul>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Extra Toolbar for spacing */}
      <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu}>
        <List>
          {menuItems.map((item, index) => (
            <div key={index} style={{ display: "inline-block" }}>
              <ListItem
                button
                component={NavLink}
                to={item.to}
                onClick={toggleMenu}
                style={{
                  fontFamily: "IRANSans",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <NavLink to={item.to} className="mobile-main-header__link">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="link-text"
                      style={{ marginInline: "0.3rem", color: "lightblue" }}
                    >
                      {item.icon}
                    </span>
                    <span className="link-text" style={{ fontSize: "1.5rem" }}>
                      {item.label}
                    </span>
                  </div>
                </NavLink>
              </ListItem>
            </div>
          ))}

          {isAuthenticated() &&
            isUserAdmin("Admin") &&
            [...manageSectionLinks, ...moreSectionLinks].map((item, index) => (
              <div key={index} style={{ display: "inline-block" }}>
                <ListItem
                  button
                  component={NavLink}
                  to={item.to}
                  onClick={toggleMenu}
                  style={{
                    fontFamily: "IRANSans",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <NavLink to={item.to} className="mobile-main-header__link">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        className="link-text"
                        style={{ marginInline: "0.3rem", color: "lightblue" }}
                      >
                        {item.icon}
                      </span>
                      <span
                        className="link-text"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {item.label}
                      </span>
                    </div>
                  </NavLink>
                </ListItem>
              </div>
            ))}
        </List>
      </Drawer>
    </div>
  );
};

export default ResponsiveNavbar;
