import React, { useContext, useState, useEffect } from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import { NavLink, useNavigate } from "react-router-dom";
import { RiLogoutCircleLine } from "react-icons/ri";
import Swal from "sweetalert2";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import "./Profile.css";
import ImageUploadBox from "../../Components/ImageUploadBox/ImageUploadBox";
import {
  isAuthenticated,
  isUserAdmin,
  parseJwt,
  setHeaders,
} from "../../utils";
import Loader from "../../Components/Loader/Loader";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function Profile() {
  const { setIsTabPanelOpen } = useContext(PackagesMadeContext);
  const [doctorID, setDoctorID] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [isSendingFiles, setIsSendingFiles] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (isUserAdmin(["Admin"]) || !isAuthenticated()) && navigate("/");

    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/Physician/GetLoginedInfoByuserID`, {
        headers,
      })
      .then((res) => res.status === 200 && setDoctorID(res.data.keyID))
      .catch((err) => console.log("err", err));
  }, []);

  function setImageprops(file, imageModel) {
    return new Promise((resolve) => {
      const fileData = new FormData();
      const reader = new FileReader();
      reader.onload = () => {
        const fileBytes = new Uint8Array(reader.result);
        fileData.fileName = file.name;
        fileData.keyID = 0;
        fileData.fileContent = Array.from(fileBytes).join(",");
        fileData.FileTitle = "title";
        fileData.FileDescription = imageModel;
        fileData.FilesAddress = "";
        fileData.FileType = file.name.split(".").pop();
        fileData.isImage = true;

        resolve(fileData);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  function displayImage(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });
  }

  async function handleImageUpload1(file) {
    const serverData = await setImageprops(file, "nationalcard").then(
      (res) => res
    );
    const frontData = await displayImage(file).then((res) => res);

    setImage1({ server: { ...serverData }, localUrl: frontData });
  }

  async function handleImageUpload2(file) {
    const serverData = await setImageprops(file, "medicallicense").then(
      (res) => res
    );
    const frontData = await displayImage(file).then((res) => res);

    setImage2({ server: { ...serverData }, localUrl: frontData });
  }

  function handleRemoveImage1() {
    setImage1(null);
  }

  function handleRemoveImage2() {
    setImage2(null);
  }

  function handleSubmitFiles() {
    const headers = setHeaders();

    setIsSendingFiles(true);

    const allFiles = [image1.server, image2.server];

    axios
      .post(
        `${HOST_NAME}/api/v1/Physician/${doctorID}/PhysicianFiles`,
        allFiles,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsSendingFiles(false);
          Swal.fire({
            icon: "success",
            title: "فایل ها با موفقیت ارسال شدند",
            confirmButtonText: "باشه",
          }).then((res) => navigate(0));
        }
      })
      .catch((err) => console.log("err", err));
  }

  return (
    <>
      <ResponsiveNavbar />

      <div className="profile">
        <aside>
          <ul>
            <li>
              <h1>احراز هویت</h1>
            </li>
          </ul>
        </aside>
        <main>
          <div>
            <div className="upload-container">
              <ImageUploadBox
                onImageUpload={handleImageUpload1}
                onRemoveImage={handleRemoveImage1}
                image={image1?.localUrl}
                title="کارت ملی"
              />
              <ImageUploadBox
                onImageUpload={handleImageUpload2}
                onRemoveImage={handleRemoveImage2}
                image={image2?.localUrl}
                title="نظام پزشکی"
              />
            </div>
            {isSendingFiles ? (
              <Loader />
            ) : (
              <button
                onClick={handleSubmitFiles}
                disabled={!image1 || !image2}
                className="submit-images"
              >
                ثبت مدارک
              </button>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
