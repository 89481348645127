import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SearchBar from "../../SearchBar/SearchBar";

import "./Selected_TabThree.css";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MuiRTL from "../../MuiRTL/MuiRTL";
import FormBox from "../../FormBox/FormBox ";
import { HOST_NAME } from "../../../config";
import { setHeaders } from "../../../utils";
import axios from "axios";

export default function Selected_TabThree() {
  const [cardSatrred, setCardStarred] = useState([]);
  const [countPage, setCountPage] = useState(0);
  const [CurrentPage, setCurrentPage] = useState("");

  useEffect(() => {
    if (!CurrentPage) {
      getStarredCards(1);
    } else {
      getStarredCards(CurrentPage);
    }
  }, [CurrentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getStarredCards = (pagenumber) => {
    const headers = setHeaders();

    const pageSize = 8;
    axios
      .get(`${HOST_NAME}/api/v1/Exercise_Favorite/${pagenumber},${pageSize}`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setCardStarred(res.data.exerciseslist);
          setCountPage(Math.ceil(res.data.totalcount / pageSize));
        }
      })
      .catch((err) => console.log("err", err));
  };

  function handleSearch(searchValue) {
    if (searchValue.length >= 1) {
      const localStorageData = localStorage.getItem("user");
      if (localStorageData) {
        const parsedData = JSON.parse(localStorageData);
        const token = parsedData.token;
        const pageSize = 8;
        // setIsLoading(true);

        fetch(
          // `${HOST_NAME}/api/v1/Education/GetBYFilter?pageNumber=${currentPage}&pageSize=${pageSize}&stringFilter=${searchValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((data) => {
            // setListContent(data.educationlist);
            // setIsLoading(false);
            // setCountPage(Math.ceil(data.totalcount / pageSize));
          })
          .catch((e) => console.log(e));
      }
    } else {
      // getEducationListCondition();
    }
  }

  return (
    <>
      <div>
        <div style={{ marginBottom: "30px", with: "100%" }}>
          <SearchBar handleSearch={handleSearch} style={{ with: "100%" }} />
        </div>

        <div className="container section-cardExersice">
          <div
            className="row"
            style={{
              width: "100%",
              display: "flex",
              gap: "16px",
            }}
          >
            <Grid container spacing={2}>
              <FormBox isCustomizedTab data={cardSatrred} />
            </Grid>

            <MuiRTL>
              <Stack
                spacing={2}
                direction="rtl"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px",
                }}
              >
                <Pagination
                  count={countPage}
                  color="primary"
                  onChange={handlePageChange}
                />
              </Stack>
            </MuiRTL>
          </div>
        </div>
      </div>
    </>
  );
}
