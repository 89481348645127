import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";

import ImageAvatars from "../ImageAvatar/ImageAvatar";
import VideoModal from "../VideoModal/VideoModal";

import "./CaptionFormCard2.css";

import TextEditor from "../TextEditor/TextEditor";
import { PackageID } from "../../Contexts/PackageID";
import TextFieldwithAddIcon from "../TextFildwithAddIcon/TextFildwithAddIcon";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

export default function CaptionFormCard2({ keyID, isCustomized, isReadOnly }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [isEditingParams, setIsEditingParams] = useState(false);
  const [secondaryLink, setSecondaryLinks] = useState(null);
  const [commands, setCommands] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [detailCardExerciseData, setDetailCardExerciseData] = useState({});
  const [fetchedData, setFetchedData] = useState(null);
  const [customizedFetch, setCustomizedFetch] = useState(null);
  const [videos, setVideos] = useState([]);
  const [exerciseFiles, setExerciseFiles] = useState([]);
  const [isLoding, setIsLoding] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const { packageID } = useContext(PackageID);

  const { exerciseParameters, setExerciseParameters } =
    useContext(PackagesMadeContext);

  const handleMediaClick = (media) => {
    setCurrentMedia(media);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentMedia([]);
  };

  const getInformationPopupCard = () => {
    const headers = setHeaders();
    const api = isCustomized ? `Getcustomized/${keyID}` : keyID;

    setIsLoding(true);

    axios
      .get(`${HOST_NAME}/api/v1/Exercise/${api}`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setFetchedData(res.data);
          setIsLoding(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoding(true);
      });

    isCustomized &&
      axios
        .get(`${HOST_NAME}/api/v1/Exercise/${keyID}`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setCustomizedFetch(res.data);
            setIsLoding(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoding(true);
        });
  };

  useEffect(() => {
    if (isCustomized) {
      customizedFetch &&
        setDetailCardExerciseData({
          ...fetchedData,
          links: customizedFetch.links,
        });
    } else {
      fetchedData && setDetailCardExerciseData(fetchedData);
    }
  }, [fetchedData, customizedFetch]);

  useEffect(() => {
    getInformationPopupCard();
  }, []);

  const {
    execution,
    links,
    mainPicturePath,
    name,
    otherDescription,
    preparation,
    subject1,
    subject2,
  } = detailCardExerciseData;

  const textEditorProps = {
    subject1,
    subject2,
    preparation,
    execution,
    otherDescription,
    detailCardExerciseData,
    switchPersonalization: false,
  };

  const getDataOfLinks = (link, setData) => {
    const headers = setHeaders();

    axios
      .get(link, {
        headers,
      })
      .then((res) => {
        res.status === 200 && setData(res.data);
      })
      .catch((err) => console.log("err", err));
  };
  const [videoLink, setVideoLink] = useState("");
  const [paremeterLink, setparemeterLink] = useState("");

  useEffect(() => {
    if (links) {
      const videoLinkFetch = links[0].href;
      const exerciseFilesLinkFetch = links[1].href;
      const exerciseParametersLinkFetch = links[2].href;
      setSecondaryLinks(links[2].href);

      isCustomized
        ? setparemeterLink(
            `${HOST_NAME}/api/v1/Exercise/${keyID}/ExerciseParameters/getCustomized`
          )
        : setparemeterLink(exerciseParametersLinkFetch);

      setVideoLink(videoLinkFetch);

      getDataOfLinks(videoLinkFetch, setVideos);

      getDataOfLinks(exerciseFilesLinkFetch, setExerciseFiles);

    }
  }, [links]);

  useEffect(() => {
    if (exerciseFiles.length > 0) {
      setDataReceived(true);
    }
  }, [exerciseFiles]);

  useEffect(() => {
    if (videos.length > 0) {
      setDataReceived(true);
    }
  }, [videos]);

  useEffect(() => {
    const exerciseExist = exerciseParameters.find(
      (exercise) => exercise.exerciseID === keyID
    );


    setExerciseParameters((prevState) => {
      if (exerciseExist) {
        return [
          ...prevState.filter((state) => state.exerciseID !== keyID),
          { exerciseID: keyID, params: parameters },
        ];
      } else {
        return [...prevState, { exerciseID: keyID, params: parameters }];
      }
    });
  }, [parameters]);

  return (
    <div className="caption-card row">
      {/* section 1 */}
      <div className="section-header-caption-cardExersice">
        <div className="avtar-header-caption-cardExersice">
          <ImageAvatars fontSize="large" imgSrc={mainPicturePath} />
        </div>
      </div>

      {/* section 2 */}
      <div className="section-name-caption-cardExersice">{name}</div>

      {/* section 3 Form information */}
      <TextFieldwithAddIcon
        readonly={isReadOnly}
        commands={commands}
        setCommands={setCommands}
        isCustomizedTab={isCustomized}
        setParameters={setParameters}
        link={paremeterLink}
        secondaryLink={secondaryLink}
        keyID={keyID}
        exerciseParameters={exerciseParameters.find(
          (exercise) => exercise.exerciseID === keyID
        )}
      />

      {/* section 4 Detail*/}
      <TextEditor {...textEditorProps} />

      {/* section 5 video and images*/}
      <div className="container-fluid section-video-image-caption-cardExersice">
        <div>
          <Button
            className="show-video-btn"
            onClick={() => handleMediaClick(exerciseFiles)}
          >
            نمایش تصاویر و ویدیو ها
          </Button>

          {dataReceived ? (
            <VideoModal
              isOpen={modalOpen}
              handleClose={handleCloseModal}
              media={currentMedia}
            />
          ) : (
            <p style={{ color: "red", fontFamily: "IRANSans" }}>
              هیچ تصویر و ویدویی برای نمایش وجود ندارد .
            </p>
          )}
        </div>
      </div>

      {/* section 6 icon upload , +*/}
      {/* <div className='section-iconeFinal-caption-cardExersice'>
        <Button>
          <FileUploader>
            <FileUploadIcon fontSize='large'/>
          ارسال فایل
          </FileUploader>
          
        </Button>

        <Button>
          <AddIcon fontSize='large'/>
            خلاصه
        </Button>
      </div> */}
    </div>
  );
}
