import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";
import Swal from "sweetalert2";

const MAX_FILE_SIZE = 20 * 1024 * 1024; 

const MultiFileUploader = ({ files, setFiles }) => {
  const [loading, setLoading] = useState(false);

  const validateFile = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");

    if (isImage || isVideo) {
      Swal.fire({
        icon: "error",
        text: "آپلود فایل‌های عکس و ویدیو مجاز نیست.",
      });
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      Swal.fire({
        icon: "error",
        text: "حجم فایل نباید بیشتر از 20 مگابایت باشد.",
      });
      return false;
    }

    return true;
  };

  const convertToFileData = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileBytes = new Uint8Array(reader.result);
        const fileType = file.name.split(".").pop();

        resolve({
          fileName: file.name,
          fileContent: Array.from(fileBytes).join(","),
          fileTitle: "title",
          fileDescription: "description",
          filesAddress: "",
          keyID: 0,
          fileType: fileType,
          isImage: false, 
          preview: null, 
        });
      };
      reader.readAsArrayBuffer(file);
    });

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(validateFile);

    if (validFiles.length === 0) return;

    setLoading(true);

    const formattedFiles = await Promise.all(
      validFiles.map((file) => convertToFileData(file))
    );

    setFiles((prev) => [...prev, ...formattedFiles]);
    setLoading(false);
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prev) => prev.filter((file) => file.fileName !== fileName));
  };

  return (
    <div className="multi-file-uploader">
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className="mb-4"
      />
      {loading && <p>در حال پردازش فایل‌ها...</p>}
      <div className="file-previews">
        {files.map((file) => (
          <div
            key={file.fileName}
            className="file-preview"
            style={{ position: "relative" }}
          >
            <button
              onClick={() => handleRemoveFile(file.fileName)}
              className="remove-btn"
            >
              <TiDelete className="icon" />
            </button>
            <p>{file.fileName}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiFileUploader;
