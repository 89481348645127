import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import axios from "axios";
import { HOST_NAME } from "../../config";
import TextFieldwithAddIcon from "../../Components/TextFildwithAddIcon/TextFildwithAddIcon";
import { setHeaders } from "../../utils";
import SingleParameter from "../../Components/TextFildwithAddIcon/SingleParameter";
import { Add, Cancel } from "@mui/icons-material";

function Modalsetparameters({ isOpen, onClose, Idd }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [oldParameters, setOldParameters] = useState([]);
  const [commands, setCommands] = useState([]);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    getParameters();
  }, []);

  function getParameters() {
    const headers = setHeaders();

    setIsLoading(true);

    axios
      .get(`${HOST_NAME}/api/v1/Exercise/${Idd}/ExerciseParameters`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setOldParameters(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err)
        setIsLoading(false);
      });
  }

  function handleTitleChange(index, newTitle) {
    const updatedCommands = [...commands];
    updatedCommands[index].paramName = newTitle;
    setCommands(updatedCommands);
  }

  function handleValueChange(index, newValue) {
    const updatedCommands = [...commands];
    updatedCommands[index].paramValue = newValue;
    setCommands(updatedCommands);
  }

  function handleRemoveCommand(index) {
    const updatedCommands = commands.filter((_, i) => i !== index);
    setCommands(updatedCommands);
  }

  function handleParamNameChange(keyID, newName) {
    const updatedParametersIncluds = oldParameters.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramName: newName };
      }
      return p;
    });
    setOldParameters(updatedParametersIncluds);
  }

  function handleParamValueChange(keyID, newValue) {
    const updatedParametersIncluds = oldParameters.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramValue: newValue };
      }
      return p;
    });
    setOldParameters(updatedParametersIncluds);
  }

  function handleRemoveParametersIncludes(index) {
    const updatedParametersIncluds = oldParameters.filter(
      (i) => i.keyID !== index
    );
    setOldParameters(updatedParametersIncluds);
  }

  function handleAddCommand(newTitle, newValue, keyID) {
    const min = 100000;
    const max = 999999;

    const newCommand = {
      keyID: Math.floor(Math.random() * (max - min + 1)) + min,
      paramName: newTitle,
      paramValue: newValue,
      paramType: "string",
      isDefault: false,
    };
    setCommands([...commands, newCommand]);
    setTitle("");
    setValue("");
  }

  function submitParams() {
    const headers = setHeaders();
    setIsSaving(true);
    axios
      .post(
        `${HOST_NAME}/api/v1/Exercise/${Idd}/ExerciseParameters`,
        [...oldParameters, ...commands],
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setCommands([]);
          getParameters();
          setIsSaving(false);
        }
      })
      .catch((error) => {
        console.error("Error sending parameters:", error);
      });
  }

  const singleParameterProps = {
    handleParamNameChange,
    handleParamValueChange,
    handleRemoveParametersIncludes,
    isReadonly: false,
    hasDeleteBtn: true,
  };

  return (
    <Modal
      className="modal-add-edit params-container"
      open={isOpen}
      onClose={onClose}
    >
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>تعیین پارامتر های تمرین</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          {oldParameters?.length
            ? oldParameters.map((p) => (
                <SingleParameter
                  key={p.keyID}
                  param={p}
                  {...singleParameterProps}
                />
              ))
            : null}

          {commands?.map((command, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                padding: "1rem",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item md={6} xs={10}>
                  <TextField
                    label="عنوان"
                    variant="filled"
                    fullWidth
                    value={command.paramTitle}
                    onChange={(e) => handleTitleChange(index, e.target.value)}
                  />
                </Grid>
                <Grid item md={4} xs={10}>
                  <TextField
                    label="مقدار"
                    variant="filled"
                    fullWidth
                    value={command.paramValue}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <IconButton
                    onClick={() => handleRemoveCommand(index)}
                    color="secondary"
                  >
                    <Cancel style={{ color: "rgba(176,56,60,255)" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}

          <div
            style={{
              display: "flex",
              margin: "1rem",
              flexDirection: "column",
            }}
          >
            <Button
              variant="outlined"
              color="success"
              onClick={() => handleAddCommand(title, value, Idd)}
              style={{ margin: "0.5rem", borderRadius: "0px" }}
            >
              <Add fontSize="large" />
            </Button>
          </div>

          <div>
            <Button
              onClick={submitParams}
              style={{
                width: "100%",
                backgroundColor: "#5eca22",
                color: "#fff",
                padding: "6px",
              }}
            >
              {isSaving ? "در حال ذخیره کردن" : "ذخیره"}
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default Modalsetparameters;
