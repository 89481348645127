import React from "react";


import CardExercise from "../CardExercise/CardExersice";

const FormBox = ({
  data,
  isShowTreemenu,
  isCustomizedTab,
  isFromLibrary,
  onRemoveExercise,
}) => {

  return (
    data?.length > 0 &&
    data.map((card) => (
      <div
        key={card.keyID}
        className="card-container"
        style={{
          flex: "4 0 calc(25% - 16px)",
          margin: "8px",
        }}
      >
        <CardExercise
          onRemoveExercise={onRemoveExercise}
          isCustomizedTab={isCustomizedTab}
          isFromLibrary={isFromLibrary}
          {...card}
          isShowTreemenu={isShowTreemenu}
        />
      </div>
    ))
  );
};

export default FormBox;
