import React, { useEffect, useRef, useState } from "react";
import "./CustomCaptcha.css";
import { FaSync } from "react-icons/fa";
const CustomCaptcha = ({ setCaptchaText }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lengthOtp = 6;
    let captcha = [];
    for (let i = 0; i < lengthOtp; i++) {
      const index = Math.floor(Math.random() * charsArray.length);
      captcha.push(charsArray[index]);
    }
    const captchaString = captcha.join("");
    setCaptchaText(captchaString);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = "30px Arial";
    ctx.fillStyle = "#000";
    ctx.fillText(captchaString, 120, 30);

    const angle = (Math.random() * 20 * Math.PI) / 180; 
    ctx.strokeStyle = "#111";
    ctx.lineWidth = 2;

    ctx.save();

    ctx.translate(canvas.width / 2, canvas.height / 3);
    ctx.rotate(angle);

    ctx.beginPath();
    ctx.moveTo(-canvas.width / 2, 0);
    ctx.lineTo(canvas.width / 2, 0);
    ctx.stroke();

    ctx.restore();
  };

  return (
    <div className="custom-captcha">
      <button onClick={generateCaptcha}>
        <FaSync />
      </button>
      <canvas ref={canvasRef} width="120" height="40" />
    </div>
  );
};

export default CustomCaptcha;
