import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import { Box, Tabs, Tab, CircularProgress } from "@mui/material";

import { CustomTabPanel } from "../../Components/Tab/Tab";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PublicIcon from "@mui/icons-material/Public";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import "./Templates.css";

import SingleTemplate from "../../Components/SingleTemplate/SingleTemplate";
import { isUserAdmin, setHeaders } from "../../utils";
import { HOST_NAME } from "../../config";
import axios from "axios";

export default function Templates() {
  const [templates, setTemplates] = useState([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    value === 1 ? getAllTemplates(false) : getAllTemplates(true);
  }, [value]);

  function getAllTemplates(isSite) {
    setIsTemplatesLoading(true);

    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/Template?isSite=${isSite}`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setTemplates(res.data);
          setIsTemplatesLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <>
      <ResponsiveNavbar />

      <MuiRTL>
        <Box className="templates-container" sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                icon={<PublicIcon style={{ marginInline: "0.5rem" }} />}
                iconPosition="start"
                label="الگوهای سایت"
                {...a11yProps(0)}
              />
              {isUserAdmin(["Admin"]) && (
                <Tab
                  icon={<EngineeringIcon style={{ marginInline: "0.5rem" }} />}
                  iconPosition="start"
                  label="الگوهای من"
                  {...a11yProps(1)}
                />
              )}
            </Tabs>
          </Box>
          {/* <button onClick={() => setIsOpenFilter(true)} className="filter-btn">
            <TuneOutlinedIcon /> فیلتر
          </button>

          <Dialog
            className="filter-dialog"
            open={isOpenFilter}
            onClose={() => setIsOpenFilter(false)}
          >
            <Treemenu data={treeMenuDataExerciseCartData} />
          </Dialog> */}
          {isTemplatesLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                margin: "1.5rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <CustomTabPanel value={value} index={0}>
                <div className="tab-container">
                  <div className="templates-wrapper">
                    {templates.map((data) => (
                      <SingleTemplate
                        key={data.keyID}
                        templateID={data.keyID}
                        setTemplates={setTemplates}
                        {...data}
                        isAllowToDelete={!isUserAdmin(["Admin"])}
                      />
                    ))}
                  </div>
                </div>
              </CustomTabPanel>

              {isUserAdmin(["Admin"]) && (
                <CustomTabPanel value={value} index={1}>
                  <div className="tab-container">
                    {/* <div className="col-2 section-treemenu-tab2 tree-view">
                      <Treemenu data={treeMenuDataExerciseCartData} />
                    </div> */}
                    <div className="templates-wrapper">
                      {templates.map((data) => (
                        <SingleTemplate
                          key={data.keyID}
                          templateID={data.keyID}
                          setTemplates={setTemplates}
                          {...data}
                          isAllowToDelete={isUserAdmin(["Admin"])}
                        />
                      ))}
                    </div>
                  </div>
                </CustomTabPanel>
              )}
            </>
          )}
        </Box>
      </MuiRTL>
    </>
  );
}
