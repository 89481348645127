import React, { useState, useEffect, useContext } from "react";
import { TextField, Button, IconButton, Grid, Paper } from "@mui/material";
import { Add, Cancel } from "@mui/icons-material";
import MuiRTL from "../MuiRTL/MuiRTL";

import "./TextFildwithAddIcon.css";
import { PackagesMadeContext } from "../../Contexts/PackagesMade";
import { setHeaders } from "../../utils";
import axios from "axios";
import SingleParameter from "./SingleParameter";

const TextFieldwithAddIcon = ({
  setParameters,
  link,
  secondaryLink,
  keyID,
  readonly,
  commands,
  setCommands,
  isSaved,
  isCustomizedTab,
  exerciseParameters,
}) => {
  const [parametersIncluds, setParametersIncluds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");

  const handleTitleChange = (index, newTitle) => {
    const updatedCommands = [...commands];
    updatedCommands[index].paramName = newTitle;
    setCommands(updatedCommands);
  };

  const handleValueChange = (index, newValue) => {
    const updatedCommands = [...commands];
    updatedCommands[index].paramValue = newValue;
    setCommands(updatedCommands);
  };

  const handleParamNameChange = (keyID, newName) => {
    const updatedParametersIncluds = parametersIncluds.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramName: newName };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };

  const handleParamValueChange = (keyID, newValue) => {
    const updatedParametersIncluds = parametersIncluds.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramValue: newValue };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };

  const handleAddCommand = (newTitle, newValue, keyID) => {
    const min = 100000;
    const max = 999999;

    const newCommand = {
      keyID: Math.floor(Math.random() * (max - min + 1)) + min,
      paramName: newTitle,
      paramValue: newValue,
      paramType: "string",
      isDefault: false,
    };
    setCommands([...commands, newCommand]);
    setTitle("");
    setValue("");
  };

  const handleRemoveCommand = (index) => {
    const updatedCommands = commands.filter((_, i) => i !== index);
    setCommands(updatedCommands);
  };

  const handleRemoveParametersIncludes = (index) => {
    const updatedParametersIncluds = parametersIncluds.filter(
      (i) => i.keyID !== index
    );
    setParametersIncluds(updatedParametersIncluds);
  };

  const getApiData = (link) => {
    const headers = setHeaders();

    setIsLoading(true);
    axios
      .get(link, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          res.data.length === 0
            ? axios
                .get(secondaryLink, {
                  headers,
                })
                .then((result) => {
                  result.status === 200 && setParametersIncluds(result.data);
                })
                .catch((err) => console.log("err", err))
            : setParametersIncluds(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (exerciseParameters) {
      setParametersIncluds([...exerciseParameters.params]);
      return;
    }
    (link || isSaved || isCustomizedTab) && getApiData(link);
  }, [link, isSaved, isCustomizedTab]);

  useEffect(() => {
    setParameters([...parametersIncluds, ...(commands || [])]);
  }, [parametersIncluds, commands]);

  const singleParameterProps = {
    handleParamNameChange,
    handleParamValueChange,
    handleRemoveParametersIncludes,
    isReadonly: readonly && !isCustomizedTab,
    hasDeleteBtn: !readonly || isCustomizedTab,
  };

  return (
    <div>
      <MuiRTL>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {commands?.map((command, index) => (
              <Paper
                key={index}
                elevation={3}
                style={{
                  padding: "1rem",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      label="عنوان"
                      variant="filled"
                      fullWidth
                      value={command.paramTitle}
                      onChange={(e) => handleTitleChange(index, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="مقدار"
                      variant="filled"
                      fullWidth
                      value={command.paramValue}
                      onChange={(e) => handleValueChange(index, e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => handleRemoveCommand(index)}
                      color="secondary"
                    >
                      <Cancel style={{ color: "rgba(176,56,60,255)" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}

            {parametersIncluds?.length
              ? parametersIncluds.map((p) => (
                  <SingleParameter
                    key={p.keyID}
                    param={p}
                    {...singleParameterProps}
                  />
                ))
              : null}

            {(!readonly || isCustomizedTab) && (
              <div
                style={{
                  display: "flex",
                  margin: "1rem",
                  flexDirection: "column",
                }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleAddCommand(title, value, keyID)}
                  style={{ margin: "0.5rem", borderRadius: "0px" }}
                >
                  <Add fontSize="large" />
                </Button>
              </div>
            )}
          </>
        )}
      </MuiRTL>
    </div>
  );
};

export default TextFieldwithAddIcon;
