import React, { useState, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { PackageID } from "../../../Contexts/PackageID";
import { EducationSelectedInModalInexPage } from "../../../Contexts/EducationSelectedInModalInexPageContext";
import { SelectionEducationIDContext } from "../../../Contexts/SelectionEducationID";
import { Dialog, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./ListItemEducationIndex.css";
import { AddCircle } from "@mui/icons-material";
import { posetEducationForPackage } from "../../../utils";
import { PackagesMadeContext } from "../../../Contexts/PackagesMade";
import useEducationFiles from "../../../Hooks/useEducationFiles";
import DisplayImages from "../../DisplayImages/DisplayImages";

export default function ListItemEducationIndex({
  keyID,
  title,
  subTitle,
  content,
  subContent,
  education_Files,
  mainPicturePath,
  isForTemplate,
  isForBuilder,
  isForClient,
}) {
  const {
    educationID,
    setEducationID,
    educationData,
    setEducationData,
    educationFiles,
    setEducationFiles,
  } = useEducationFiles();
  const [isChecked, setIsChecked] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { packageID } = useContext(PackageID);
  const { educationSelected, setEducationSelected } = useContext(
    EducationSelectedInModalInexPage
  );
  const { isTabPanelOpen, setIsTabPanelOpen } =
    useContext(PackagesMadeContext);
  const { selectedItemIDs, setSelectedItemIDs } = useContext(
    SelectionEducationIDContext
  );

  const deletOfListEducation = (id) => {
    const updatedData =
      selectedItemIDs && selectedItemIDs.filter((eduID) => eduID !== id);
    const updatedData2 =
      educationSelected && educationSelected.filter((edu) => edu.keyID !== id);
    setSelectedItemIDs(updatedData);
    setEducationSelected(updatedData2);
  };


  function handleAddExercise(isPanelOpen, card) {
    if (isPanelOpen) {
      setEducationSelected((prev) => [...prev, card]);
    } else {
      setIsTabPanelOpen(true);
      handleAddExercise(true, card);
    }
  }

  return (
    <>
      <List
        className="package-exercises"
        sx={{
          width: "100%",
          maxWidth: isForTemplate ? "30%" : 360,
          bgcolor: "background.paper",
        }}
      >
        <ListItem
          className="list-container"
          alignItems="flex-start"
          style={{ border: "1px dashed", width: "100%" }}
        >
          <ListItem
            onClick={() => {
              if (isForTemplate || isForBuilder || isForClient) {
                setIsDialogOpen(true);
                setEducationID(keyID);
                setEducationData({});
              }
            }}
          >
            <div className="avatar">
              <DisplayImages item={{ mainPicturePath }} />
            </div>
            <ListItemText
              style={{ fontFamily: "IRANSans" }}
              secondary={
                <>
                  {!isForTemplate && !isForClient && (
                    <Checkbox
                      checked={isChecked}
                      onChange={() => {
                        setIsChecked(!isChecked);
                        if (!isChecked) {
                          posetEducationForPackage(keyID, packageID);
                        }
                      }}
                      color="primary"
                    />
                  )}
                  <Typography
                    sx={{
                      display: "inline",
                      fontFamily: "IRANSans",
                      fontSize: "1.2rem",
                      color: "black",
                    }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {title}
                  </Typography>
                  <div style={{ fontFamily: "IRANSans" }}>{content}</div>
                  <div style={{ fontFamily: "IRANSans" }}>{subContent}</div>
                </>
              }
            />
          </ListItem>

          {isForTemplate || isForBuilder ? (
            !educationSelected.some((card) => card.keyID === keyID) ? (
              <AddCircle
                fontSize="large"
                color="primary"
                className="add-to-build"
                onClick={() => {
                  handleAddExercise(isTabPanelOpen, {
                    keyID,
                    title,
                    subTitle,
                    content,
                    subContent,
                    education_Files,
                    mainPicturePath,
                  });
                }}
                style={{
                  backgroundColor: "primary",
                  color: "primary",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            ) : (
              <div
                className="add-to-build"
                style={{
                  fontFamily: "IRANSans",
                  color: "#e44da5",
                  backgroundColor: "primary",
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
              >
                این آموزش افزوده شده است.
              </div>
            )
          ) : !isForClient ? (
            <IconButton>
              <CancelIcon
                style={{ color: "rgba(176,56,60,255)" }}
                onClick={() => deletOfListEducation(keyID)}
              />
            </IconButton>
          ) : null}
        </ListItem>

        <Divider variant="inset" component="li" />
      </List>

      <Dialog
        className="education-dialog"
        open={educationFiles}
        onClose={() => {
          setEducationID(0);
          setEducationData(null);
          setEducationFiles(null);
        }}
      >
        <div className="header">
          <h6>{title}</h6>
          <button
            onClick={() => {
              setEducationID(0);
              setEducationData(null);
              setEducationFiles(null);
            }}
          >
            بستن
          </button>
        </div>
        <div className="main-content">
          <DisplayImages item={{ mainPicturePath }} />
          <div className="full-content">
            <p>{content}</p>
          </div>
        </div>
        <ul className="files-container">
          {educationFiles?.map((file) => (
            <li key={file.keyID}>
              <a
                download={`${file.fileName}.${file.fileType}`}
                href={file.filesAddress}
              >
                {file.fileName}.{file.fileType}
              </a>
            </li>
          ))}
        </ul>
      </Dialog>
    </>
  );
}
