import { useContext } from "react";
import { PackageID } from "../Contexts/PackageID";
import { HOST_NAME } from "../config";
import { setHeaders } from "../utils";
import axios from "axios";

export default function useMakePackage() {
  const { updatePackageIDVariable } = useContext(PackageID);

  const getPackageNme = ({ content }, handleFn) => {
    const headers = setHeaders();
    const newPackage = {
      keyID: 0,
      packageName: content?.contentTitle || "",
      packageDescription: content?.contentDescription || "",
    };

    axios
      .post(`${HOST_NAME}/api/v1/Package`, newPackage, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          handleFn && handleFn(res.data);
          updatePackageIDVariable(res.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return { getPackageNme };
}
