import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import AuthContext from "../../Contexts/authContext";

import Swal from "sweetalert2";

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { SubscriptionContext } from "../../Contexts/Subscriptions";
import { isUserRoleActive } from "../../utils";
import { HOST_NAME } from "../../config";
import axios from "axios";

const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function SignInSide() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { choosedSubscriptions, setChoosedSubscriptions } =
    useContext(SubscriptionContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleRecaotchVerify, setIsGoogleRecaotchVerify] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsLoading(true);

    const userData = {
      userName: data.get("userName"),
      password: data.get("password"),
    };
    axios
      .post(`${HOST_NAME}/api/v1/User/login`, userData)
      .then((res) => {
        setIsLoading(false);
        setIsFormValid(true);
        authContext.login({}, res.data[0]);
        Swal.fire({
          title: "با موفقیت لاگین شدید",
          icon: "success",
          confirmButtonText: choosedSubscriptions.length
            ? "ورود به سبد خرید"
            : "ورود به پنل",
        }).then((result) => {
          if (result.isConfirmed) {
            if (isUserRoleActive()) {
              Swal.fire({
                icon: "info",
                title: "اشتراک فعالی ندارید. برای ثبت سفارش اقدام کنید",
              }).then(() => {
                navigate("/subscriptions");
              });
            }
            choosedSubscriptions.length ? navigate("/basket") : navigate("/");
          } else {
            Swal.fire("تلاش دوباره", "درخواست تکراری", "error");
          }
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
        Swal.fire({
          title: "نام کاربری یا رمز عبور اشتباه است",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "تلاش دوباره",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        });
      });
  };

  const onChangeHandler = () => {
    setIsGoogleRecaotchVerify(true);
  };

  return (
    <MuiRTL>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <video autoPlay loop muted style={styles.videoBackground}>
            <source src="./videos/istockphoto.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className="blurredBackground"
          >
            <div className="login-form" style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Link to="/" className="logo" />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontFamily: "IRANSans", color: "#042b89" }}
                >
                  ورود به سیستم
                </Typography>
              </div>

              <form
                style={{ fontFamily: "IRANSans" }}
                noValidate
                onSubmit={handleSubmit}
              >
                <TextField
                  style={{ fontFamily: "IRANSans" }}
                  margin="normal"
                  required
                  fullWidth
                  id="userName"
                  label="نام کاربری"
                  name="userName"
                  autoFocus
                />

                <FormControl
                  sx={{
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "center",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    رمز
                  </InputLabel>
                  <OutlinedInput
                    required
                    style={{ fontSize: "1.5rem" }}
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff style={{ fontSize: "2.5rem" }} />
                          ) : (
                            <Visibility style={{ fontSize: "2.5rem" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  className="login-form-btn-successfuly"
              
                >
                  {isLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "#fff" }} />
                    </Box>
                  ) : (
                    <p style={{ fontFamily: "IRANSans", color: "white" }}>
                      ورود
                    </p>
                  )}
                </Button>

                <Grid container>
                  <Grid item xs>
                    <Link to="/register">
                      <p style={{ fontFamily: "IRANSans", fontSize: "1.4rem" }}>
                        حساب ندارید؟ ثبت نام کنید
                      </p>
                    </Link>
                  </Grid>
                  <Grid item xs>
                    <Link to="/recovery">
                      <p style={{ fontFamily: "IRANSans", fontSize: "1.4rem" }}>
                        رمز خود را فراموش کردید ؟
                      </p>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiRTL>
  );
}
