import React, { useEffect, useState } from "react";
import ResponsiveNavbar from "../../Components/ResponsiveNavbar/ResponsiveNavbar";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import { Stack } from "react-bootstrap";
import { Box, Button, CircularProgress, Pagination } from "@mui/material";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Swal from "sweetalert2";
import { setHeaders } from "../../utils";
import axios from "axios";
import { HOST_NAME } from "../../config";

import "../ManageExercise/ManageExercise.css";
import "./ManageEducation.css";
import ModalEdu from "./ModalEdu";
import ModalAttachEduFile from "./ModalAttachEduFile";
import ModalGroup from "../ManageExercise/ModalGroup";
import { VscGroupByRefType } from "react-icons/vsc";

export default function ManageEducation() {
  const [isLoading, setIsLoading] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAttachModalOpen, setAttachModalOpen] = useState(false);
  const [educationID, setEducationID] = useState(null);
  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [countPage, setCountPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [userInfo, setUserInfo] = useState(null);

  useState(() => {
    const headers = setHeaders();
    axios
      .get(`${HOST_NAME}/api/v1/Physician/GetLoginedInfoByuserID`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setUserInfo(res.data);
        }
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    refreshlist();
  }, [currentPage]);

  function refreshlist() {
    const limit = 8;
    const headers = setHeaders();
    setIsLoading(true);
    axios
      .get(
        `${HOST_NAME}/api/v1/Education/GetMyEducationList/-1,${currentPage},${limit},-1`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setEducationList(res.data.educationlist);
          setCountPage(Math.ceil(res.data.totalcount / limit));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }

  function handleEditClick(eduID) {
    setEducationID(eduID);
    setEditModalOpen(true);
  }

  function handleAttachModal(eduID) {
    setEducationID(eduID);
    setAttachModalOpen(true);
  }

  function removeEducation(eduID) {
    setEducationID(eduID);

    Swal.fire({
      icon: "question",
      title: "آیا از حذف این آموزش مطمئن هستید؟",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "لغو",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();
        axios
          .delete(`${HOST_NAME}/api/v1/education/${eduID}`, { headers })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "آموزش با موفقیت حذف شد",
              });
              refreshlist();
            }
          })
          .catch((error) => {
            console.error("خطا در درخواست به API برای حذف آموزش: ", error);
          });
      }
    });
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function handleGroupingModal(education) {
    setGroupModalOpen(true);
    setSelectedEducation(education);
  }

  return (
    <>
      <ResponsiveNavbar />
      <div className="management-container">
        <h1>مدیریت آموزش های من</h1>
        <div className="management-actions">
          <Button onClick={() => handleEditClick(0)} variant="contained">
            افزودن
            <AddCircleOutlineIcon className="management-add-icon" />
          </Button>
        </div>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              margin: "1rem",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          educationList.length > 0 && (
            <>
              <table>
                <thead>
                  <tr className="text-sm">
                    <th>شناسه</th>
                    <th>عنوان</th>
                    <th className="edu-content">توضیحات</th>
                    <th>عملیات</th>
                  </tr>
                </thead>

                <tbody>
                  {educationList.map((education) => (
                    <tr key={education.keyID}>
                      <td>{education.keyID}</td>
                      <td>{education.title}</td>
                      <td className="edu-content">{education.content}</td>
                      <td className="actions">
                        <div>
                          <button
                            onClick={() => handleEditClick(education.keyID)}
                          >
                            <FaEdit />
                          </button>
                          <button
                            onClick={() => handleAttachModal(education.keyID)}
                          >
                            <AiFillFileAdd />
                          </button>

                          <button
                            onClick={() => handleGroupingModal(education)}
                          >
                            <VscGroupByRefType />
                          </button>
                          <button
                            onClick={() => removeEducation(education.keyID)}
                            title="Delete"
                          >
                            <FaTrashAlt />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <MuiRTL>
                <Stack
                  spacing={2}
                  direction="rtl"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <Pagination
                    className="edu-pagination"
                    count={countPage}
                    page={currentPage}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </MuiRTL>
            </>
          )
        )}
      </div>

      {isEditModalOpen && (
        <ModalEdu
          educationLimit={userInfo.educationLimit}
          refresh={refreshlist}
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          Idd={educationID}
        />
      )}

      {isAttachModalOpen && (
        <ModalAttachEduFile
          eduFileLimit={userInfo.eduFileLimit}
          isOpen={isAttachModalOpen}
          onClose={() => setAttachModalOpen(false)}
          Idd={educationID}
          refresh={refreshlist}
        />
      )}

      {isGroupModalOpen && (
        <ModalGroup
          isOpen={isGroupModalOpen}
          onClose={() => setGroupModalOpen(false)}
          content={selectedEducation}
          getCategoriesLink="EducationCtg/GetCategoriesByEduID?eduID="
          setCategoriesLink="Education/SetCategories"
          treeLink="EducationCtg"
          field="educationID"
        />
      )}
    </>
  );
}
