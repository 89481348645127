import React, { useEffect, useState } from "react";
import { HOST_NAME } from "../config";
import { setHeaders } from "../utils";
import axios from "axios";

export default function useEducationFiles() {
  const [educationID, setEducationID] = useState(0);
  const [educationData, setEducationData] = useState(null);
  const [educationFiles, setEducationFiles] = useState(null);

  useEffect(() => {
    if (educationID && educationData) {
      const headers = setHeaders();

      axios
        .get(`${HOST_NAME}/api/v1/Education/${educationID}/EducationFiles`, {
          headers,
        })
        .then((res) => res.status === 200 && setEducationFiles(res.data.result))
        .catch((error) => {
          console.error("خطا در ارتباط با سرور: ", error);
        });
    }
  }, [educationID, educationData]);

  return {
    educationID,
    setEducationID,
    educationData,
    setEducationData,
    educationFiles,
    setEducationFiles,
  };
}
