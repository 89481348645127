import axios from "axios";
import Swal from "sweetalert2";

// const HOST_NAME = "https://test.meritcenter.ir";
 const HOST_NAME = "https://api.bettermove.ir";
//const HOST_NAME = "https://test.bettermove.ir";

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log("err", err.status);
    if (err.status === 403 || err.status === 401) {
      Swal.fire({
        icon: "error",
        title: "شما دسترسی به این بخش ندارید",
      });
    }

    return Promise.reject(err);
  }
);

export { HOST_NAME };
