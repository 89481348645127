import React, { useState, useEffect, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";

import "./PackageListClient.css";
import { UserLoginCodeContext } from "../../Contexts/UserLoginCode";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CaptionFormCardForClient from "./CaptionFromCardForClient";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

export default function AlignItemsList() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [item_Data, setItem_Data] = useState([]);
  const [keyIDPackages, setKeyIDPackages] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [isLodingGetData, setIsLodingGetData] = useState(false);
  const [educationsPatientPackages, setEducationsPatientPackages] = useState(
    []
  );
  const [exercisePatientPackages, setexercisePatientPackages] = useState([]);

  const { userLoginCode, setUserLoginCode } = useContext(UserLoginCodeContext);

  const [expandedRow, setExpandedRow] = useState(null);
  const [patPackageid, setpatPackageid] = useState(null);
  const handleExpandClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleDeleteRow = (id, index) => {};

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    setKeyIDPackages(item.keyID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getInformationPatientPackages = (paitientID) => {
    const headers = setHeaders();

    setIsLoding(true);
    axios
      .get(`${HOST_NAME}/api/v1/Patient/${userLoginCode / 100}/Packages`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setItem_Data(res.data);
          setIsLoding(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoding(true);
      });
  };

  useEffect(() => {
    getInformationPatientPackages();
  }, [userLoginCode]);

  const getInformationEducationsPatientPackages = (patientPackageID) => {
    setpatPackageid(patientPackageID);
    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/Package/${patientPackageID}/Educations`, {
        headers,
      })
      .then((res) => {
        res.status === 200 && setEducationsPatientPackages(res.data);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const getInformationExercisePatientPackages = (patientPackageID) => {
    const headers = setHeaders();

    setIsLodingGetData(true);
    axios
      .get(`${HOST_NAME}/api/v1/Package/${patientPackageID}/Exercises`, {
        headers,
      })
      .then((res) => {
        res.status === 200 && setexercisePatientPackages(res.data);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLodingGetData(true);
      });
  };

  useEffect(() => {
    if (item_Data.length > 0 && !isLoding) {
      getInformationEducationsPatientPackages(keyIDPackages);
      getInformationExercisePatientPackages(keyIDPackages);
    }
  }, [item_Data, isLoding, keyIDPackages]);

  const [educationsFiles, setEducationsFiles] = useState([]);
  const [exerciseFiles, setExerciseFiles] = useState([]);

  const clickToEduCation = (id) => {
    setEducationsFiles([]);
    setExerciseFiles([]);
    const headers = setHeaders();

    axios
      .get(`${HOST_NAME}/api/v1/Education/${id}/EducationFiles`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setModalOpen(true);
          setEducationsFiles(res.data.result);
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {item_Data.length === 0 && !isLoding && (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Alert
            severity="info"
            style={{
              textAlign: "center",
              marginTop: "1rem",
              fontFamily: "IRANSans",
              fontSize: "1.3rem",
            }}
          >
            پکیج فعالی برای شما وجود ندارد.
          </Alert>
        </div>
      )}

      {item_Data.length > 0 && (
        <div
          style={{
            padding: "20px",
            backgroundImage: "url(./images/human-skeleton.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            backgroundAttachment: "fixed",
          }}
        >
          <List
            style={{
              width: "100%",
              maxWidth: 1000,
              margin: "auto",
              marginTop: "1rem",
              border: "5px solid rgb(25, 118, 210)",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              borderRadius: "1%",
              backgroundColor: "#d3e2fd",
              opacity: "0.9",
            }}
          >
            {item_Data.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() => handleListItemClick(item)}
                  style={{ border: "1px solid pink" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar ${index + 1}`}
                      src={`/static/images/avatar/${index + 1}.jpg`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.packageName}
                    style={{ fontWeight: "bold" }}
                  />
                  <ListItemText secondary={item.packageDescription} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={item.startDate}
                    secondary={item.endDate}
                  />
                </ListItem>

                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "transform 0.5s ease-in-out",
              overflowY: "auto",
           
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(209, 215, 222)",
                border: "1px solid #212529f7",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
                padding: "20px",
                outline: "none",
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter: "blur(5px)", 
                overflowY: "auto",
                maxHeight: "100%", 
              }}
            >
              <List>
                {educationsPatientPackages.length
                  ? educationsPatientPackages.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        style={{
                          border: "1px solid #5a7588",
                          backgroundColor: "#b3d5ed",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.9)",
                          borderRadius: "1%",
                        }}
                      >
                        <div>
                          {item.mainPicturePath && (
                            <div style={{ margin: "20px" }}>
                              <img
                                src={item.mainPicturePath}
                                alt=""
                                style={{ width: "50%", height: "50%" }}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <ListItemText
                            primary={item.title}
                            secondary={item.content}
                          />
                          <ListItemText
                            primary={item.subTitle}
                            secondary={item.subContent}
                          />
                          <Button
                            onClick={() => clickToEduCation(item.keyID)}
                            style={{
                              backgroundColor: "white",
                              width: "80%",
                              margin: "1rem",
                            }}
                          >
                            {" "}
                            نمایش فایلها
                          </Button>
                        </div>
                        <div></div>
                      </ListItem>
                    ))
                  : null}
              </List>

              <List>
                {exercisePatientPackages.length ? (
                  <MuiRTL>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableBody>
                          {exercisePatientPackages
                            ? exercisePatientPackages.map((item, index) => (
                                <React.Fragment key={item.keyID}>
                                  <TableRow>
                                    <TableCell>
                                      {/* <Avatar alt={`Avatar ${row.keyID}`} src="./images/566.jpg" /> */}
                                      <Avatar
                                        alt={`Avatar ${item.keyID}`}
                                        src={item.mainPicturePath}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ fontFamily: "IRANSans" }}
                                    >
                                      {item.name}
                                    </TableCell>
                                    {/* <TableCell style={{ fontFamily: 'IRANSans' }}>{row.name}</TableCell> */}
                                    <TableCell>
                                      <IconButton
                                        aria-label="expand"
                                        onClick={() => {
                                          handleExpandClick(index);
                                        }}
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() =>
                                          handleDeleteRow(item.keyID, index)
                                        }
                                      >
                                        {/* <CancelIcon style={{ color: 'rgba(176,56,60,255)' }}/> */}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                      }}
                                      colSpan={4}
                                    >
                                      <Collapse
                                        in={expandedRow === index}
                                        timeout="auto"
                                        unmountOnExit
                                        style={{ padding: "5px" }}
                                      >
                                        <CaptionFormCardForClient
                                          keyID={item.keyID}
                                          packageID={patPackageid}
                                        />
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </MuiRTL>
                ) : null}
              </List>

              {/* )) */}

              {/* } */}
              {/* </List> */}
            </div>
          </Modal>
        </div>
      )}

      {/* isOpen={modalOpen} handleClose={} media={currentMedia} */}
      <div>
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          {/* <DialogTitle>ویدئوها و تصاویر</DialogTitle> */}
          <DialogContent dividers>
            <Stack spacing={2}>
              {1 &&
                educationsFiles.map((item, index) => (
                  <div key={item.keyID} style={{ margin: "2rem" }}>
                    {item.isImage ? (
                      <div style={{ padding: "2rem", border: "1px dashed" }}>
                        <img
                          src={item.filesAddress}
                          alt={item.fileDescription}
                          style={{ width: "100%", height: "500px" }}
                        />
                        {item.fileTitle}
                      </div>
                    ) : (
                      <video width="100%" controls></video>
                    )}
                  </div>
                ))}

              {1 &&
                exerciseFiles.map((item, index) => (
                  <div key={item.keyID} style={{ margin: "2rem" }}>
                    {item.isImage ? (
                      <div style={{ padding: "1rem", border: "1px dashed" }}>
                        <img
                          src={item.filesAddress}
                          alt={item.fileDescription}
                          style={{ width: "100%", height: "500px" }}
                        />
                        {item.fileTitle}
                      </div>
                    ) : (
                      <video
                        width="100%"
                        controls
                        onError={(e) => console.error("Video error:", e)}
                      >
                        {item.fileTitle}
                        <source src={item.filesAddress} type="video/mp4" />
                        {item.fileDescription}
                        مرورگر شما ویدئو را پشتیبانی نمی‌کند.
                      </video>
                    )}
                  </div>
                ))}
            </Stack>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
