
import { createContext, useState } from "react";

export const CardExersiceContext = createContext();

const CardExersiceContextProvider = ({ children }) => {
  const [cardExersiesData, setCardExersiesData] = useState([]);
  const [keyIdItemsCardExersice, setKeyIdItemsCardExersice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPageNation, setCountPageNation] = useState("");
  const [isShowAlertError, setIsShowAlertError] = useState(false);
  const [keyIdItemsEducation, setKeyIdItemsEducation] = useState([]);

 
  return (
    <CardExersiceContext.Provider
      value={{
        cardExersiesData,
        setCardExersiesData,
        keyIdItemsCardExersice,
        setKeyIdItemsCardExersice,
        currentPage,
        setCurrentPage,
        countPageNation,
        setCountPageNation,
        isShowAlertError,
        setIsShowAlertError,
        keyIdItemsEducation,
        setKeyIdItemsEducation,
      }}
    >
      {children}
    </CardExersiceContext.Provider>
  );
};

export { CardExersiceContextProvider };
