import { useState, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import SelectBox from "../SelectBox/SelectBox";
import { Cancel } from "@mui/icons-material";
import Swal from "sweetalert2";
import { HOST_NAME } from "../../config";
import { setHeaders } from "../../utils";
import axios from "axios";

export default function NewTicketModal({
  children,
  setIsNewTicketBoxOpen,
  isNewTicketBoxOpen,
  receiverID,
  getAllTickets,
}) {
  const ticketInitialValue = {
    subject: "",
    content: "",
  };

  const [newTicket, setNewTicket] = useState(ticketInitialValue);

  function closeNewTicketBox() {
    setIsNewTicketBoxOpen(false);
    setNewTicket(ticketInitialValue);
  }

  function sendNewTicket(e) {
    if (receiverID) {
      e.preventDefault();

      const headers = setHeaders();

      const newTicketObj = {
        keyID: receiverID === 1 ? 1 : 0,
        subject: newTicket.subject,
        description: "string",
        reciverID:
          receiverID === 1
            ? "aff9e532-d603-410d-8d7d-000000000000"
            : receiverID,
        priority: 0,
        status: 0,
        newMessage: newTicket.content,
      };

      axios
        .post(`${HOST_NAME}/api/v1/Ticket`, newTicketObj, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              title: "پیام با موفقیت ارسال شد",
              icon: "success",
            });

            setNewTicket(ticketInitialValue);
            getAllTickets();
            closeNewTicketBox();
          }
        })
        .catch((error) => console.log("send meessage error", error));
    } else {
      Swal.fire({
        title: "لطفا گیرنده پیام رو مشخص کنید",
        icon: "info",
      });
    }
  }

  return (
    <Dialog
      className="new-ticket-modal"
      open={isNewTicketBoxOpen}
      onClose={closeNewTicketBox}
    >
      <Box className="top-new-ticket">
        <DialogTitle
          style={{
            fontFamily: "IRANSans",
            fontSize: "2rem",
          }}
        >
          پیام جدید
        </DialogTitle>
        <IconButton
          className="btn-close-modal"
          style={{ marginLeft: "1rem" }}
          onClick={closeNewTicketBox}
        >
          <Cancel style={{ color: "rgba(176,56,60,255)", fontSize: "2rem" }} />
        </IconButton>
      </Box>

      <DialogContent>
        {children}
        <FormControl fullWidth margin="normal">
          <TextField
            label="موضوع"
            name="subject"
            value={newTicket.subject}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                subject: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="توضیحات"
            name="content"
            value={newTicket.content}
            onChange={(e) =>
              setNewTicket({
                ...newTicket,
                content: e.target.value,
              })
            }
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={sendNewTicket} className="btn-send">
          ارسال پیام
        </Button>
      </DialogActions>
    </Dialog>
  );
}
