import React, { useState, useEffect } from "react";

import { formatDate, formatTime, parseJwt, setHeaders } from "../utils";
import { HOST_NAME } from "../config";
import axios from "axios";

export default function SingleTicketRow({
  setActiveTicket,
  changeDate,
  createDate,
  createByUserID,
  reciverID,
  hasNewmessage,
  subject,
  keyID,
  setopponent,
  setStarterUser,
  isFromClient,
}) {
  const [receiverName, setReceiverName] = useState("");
  const [starterUserName, setStarterUsername] = useState("");
  const [isNewMessage, setIsNewMessage] = useState(hasNewmessage);

  useEffect(() => {
    const localStorageData = localStorage.getItem("user");
    const user = parseJwt(localStorageData);
    if (createByUserID === user.UserID) {
      setStarterUsername("شما");
      getPatientByUserID(reciverID)
        .then((res) => setReceiverName(`${res.data.fName} ${res.data.lName}`))
        .catch(() => setReceiverName("ادمین"));
    } else {
      setReceiverName("شما");
      getPatientByUserID(createByUserID)
        .then((res) =>
          setStarterUsername(`${res.data.fName} ${res.data.lName}`)
        )
        .catch(() => setStarterUsername("ادمین"));
    }
  }, []);

  function getPatientByUserID(userID) {
    const headers = setHeaders();

    return new Promise((resolve, reject) => {
      axios
        .get(`${HOST_NAME}/api/v1/Patient/Getbyguid?userID=${userID}`, {
          headers,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("err", error);
          reject(error);
        });
    });
  }

  function showTicket() {
    setIsNewMessage(false);
    setActiveTicket(keyID);
    setopponent({
      userID: reciverID,
      fullname: receiverName,
    });
    setStarterUser({
      userID: createByUserID,
      fullname: starterUserName,
    });
  }
  return (
    <tr onClick={showTicket} className="ticket">
      {!isFromClient && (
        <>
          <td>{starterUserName}</td>
          <td>{receiverName}</td>
        </>
      )}

      <td className="ticket-subject">{subject}</td>
      <td>
        <span>
          {formatTime(createDate)} | {formatDate(createDate)}
        </span>
      </td>
      <td>
        <span>
          {formatTime(changeDate)} | {formatDate(changeDate)}
        </span>
      </td>
      <td>
        {isNewMessage ? (
          <span className="new-ticket-notif">جدید</span>
        ) : (
          "خوانده شده"
        )}
      </td>
      {/* <td>
        <Delete
          onClick={deleteTicket}
          className="delete-ticket"
          fontSize="large"
        />
      </td> */}
    </tr>
  );
}
