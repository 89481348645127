import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import AuthContext from "../../Contexts/authContext";
import { UserLoginCodeContext } from "../../Contexts/UserLoginCode";

import { useParams } from "react-router-dom";

import Swal from "sweetalert2";

import "./UserPageLogin";
import { HOST_NAME } from "../../config";
import axios from "axios";

const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function SignInSide() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { userLoginCode, setUserLoginCode } = useContext(UserLoginCodeContext);
  const { extractedNumber } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleRecaotchVerify, setIsGoogleRecaotchVerify] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const currentURL = window.location.href;
    const linkParts = currentURL.split("/");
    const number = linkParts[linkParts.length - 1];

    setUserLoginCode(number);
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userData = {
      userName: userLoginCode, 
      password: data.get("password"),
    };

    axios
      .post(`${HOST_NAME}/api/v1/User/login`, userData)
      .then((res) => {
        setIsFormValid(true);
        authContext.login({}, res.data[0]);

        Swal.fire({
          title: "با موفقیت لاگین شدید",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "بستن",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/boxList");
          } else {
            Swal.fire("تلاش دوباره", "درخواست تکراری", "error");
          }
        });
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire({
          title: "نام کاربری یا رمز عبور اشتباه است",
          icon: "error",
          showCancelButton: true, 
          showConfirmButton: false,
          cancelButtonText: "تلاش دوباره", 
          confirmButtonColor: "#3085d6", 
          cancelButtonColor: "#d33", 
        }).then((result) => {
          if (result.isConfirmed) {
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
      });
  };

  const onChangeHandler = () => {
    setIsGoogleRecaotchVerify(true);
  };

  return (
    <MuiRTL>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          {/* <video
            autoPlay
            loop
            muted
            style={styles.videoBackground}
          >
            <source src="./videos/istockphoto.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className="blurredBackground"
          >
            <div className="login-form" style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    m: 1,
                    bgcolor: "#2f9bfa",
                    width: 100, 
                    height: 100,
                    objectFit: "cover",
                  }}
                >
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontFamily: "IRANSans", color: "#042b89" }}
                >
                  ورود به سیستم
                </Typography>
              </div>

              <form noValidate onSubmit={handleSubmit}>
             

                <TextField
                  style={{ fontFamily: "IRANSans" }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="رمز"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                {/* <div style={{ marginTop: '1rem' }}>
                  <ReCAPTCHA
                  sitekey=" 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChangeHandler}
                />
                </div> */}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  className="login-form-btn-successfuly"
            
                >
                  <p style={{ fontFamily: "IRANSans", color: "white" }}>ورود</p>
                </Button>

           
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiRTL>
  );
}
