import React, { useState } from "react";
import { BiSolidLeftArrow } from "react-icons/bi";
import { RxDot } from "react-icons/rx";

import "./CustomTreeMenu.css";

export default function CustomTreeMenu({
  items = [],
  isListOpen = true,
  setSelectedGroup,
}) {
  return (
    <ul
      style={{
        maxHeight: isListOpen ? "100%" : "0",
        overflow: isListOpen ? "unset" : "hidden",
      }}
      className="treemenu-custom"
    >
      {items.map((item) => (
        <TreeItem
          key={item.keyID}
          item={item}
          setSelectedGroup={setSelectedGroup}
        />
      ))}
    </ul>
  );
}

function TreeItem({ item, setSelectedGroup }) {
  const [isShowingChildren, setIsShowingChildren] = useState(false);

  return (
    <>
      <li
        style={{
          paddingRight: item.fK_ParentID ? "1.5rem" : "",
          fontSize: item.fK_ParentID ? "1.5rem" : "",
          fontWeight: item.fK_ParentID ? "" : "bold",
        }}
        className="tree-item-custom"
        onClick={() =>
          item.children &&
          item.children.length > 0 &&
          setIsShowingChildren(!isShowingChildren)
        }
      >
        {item.children && item.children.length > 0 ? (
          <BiSolidLeftArrow
            style={{ transform: isShowingChildren ? "rotate(-90deg)" : "" }}
            className="arrow-icon"
          />
        ) : (
          <RxDot />
        )}
        <label
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedGroup(item)}
        >
          {item.ctgName}
        </label>
      </li>
      {item.children && item.children.length > 0 && (
        <CustomTreeMenu
          items={item.children}
          isListOpen={isShowingChildren}
          setSelectedGroup={setSelectedGroup}
        />
      )}
    </>
  );
}
