import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import AuthContext from "../../Contexts/authContext";

import Swal from "sweetalert2";
import * as yup from "yup";
import "../LoginPage/Login.css";

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import OTPInput from "../../Components/OPTInput/OTPInput";
import { HOST_NAME } from "../../config";
import axios from "axios";

const defaultTheme = createTheme();

const styles = {
  blurredBackground: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  videoBackground: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
};

export default function RecoveryPassword() {
  const navigate = useNavigate();
  const [isSendingData, setIsSendingData] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [recoveryStep, setRecoveryStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmCodeError, setConfirmCodeError] = useState(false);

  const userNamePhoneSchema = yup.object().shape({
    userName: yup.string().required("نام کاربری الزامی است"),

    phone: yup
      .string()
      .matches(
        /^(0|0098|\+98)9(0[1-5]|[1 3]\d|2[0-2]|98)\d{7}$/,
        "شماره تلفن معتبر نیست."
      )
      .required("شماره تلفن الزامی است"),
  });

  const passwordSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, "رمز عبور باید حداقل 6 کاراکتر داشته باشد")
      .required("رمز عبور الزامی است"),
    confirmPassword: yup
      .string()
      .required("تکرار رمز عبور الزامی است")
      .oneOf(
        [yup.ref("password"), null],
        "تکرار رمزعبور با رمزعبور مطابقت ندارد"
      ),
  });

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const validationData = Object.fromEntries(data.entries());

    setFormData(Object.fromEntries(data.entries()));
    try {
      const isFormValid = await userNamePhoneSchema.isValid(validationData);
      if (isFormValid) {
        setIsSendingData(true);
        const newUserInfos = {
          username: validationData.userName,
          mobile: validationData.phone,
          password: validationData.userName,
          confirmPassword: validationData.userName,
        };

        setFormErrors({});

        axios
          .post(`${HOST_NAME}/api/v1/User/ChangePassRequest`, newUserInfos)
          .then((res) => {
            res.status === 200 && setRecoveryStep(2);
          })
          .catch((err) => console.log("err", err))
          .finally(() => setIsSendingData(false));
      } else {
        await userNamePhoneSchema.validate(validationData, {
          abortEarly: false,
        });
      }
    } catch (errors) {
      const newErrors = {};

      errors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      console.log("errors", newErrors);
      setFormErrors(newErrors);
    }
  }

  async function handleSecondStep(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const validationData = Object.fromEntries(data.entries());

    if (!otp.some((value) => value === "")) {
      setConfirmCodeError(false);
      try {
        const isFormValid = await passwordSchema.isValid(validationData);
        if (isFormValid) {
          setIsSendingData(true);
          const newUserInfos = {
            username: formData.userName,
            mobile: formData.phone,
            password: validationData.password,
            confirmPassword: validationData.confirmPassword,
          };

          setFormErrors({});

          axios
            .post(
              `${HOST_NAME}/api/v1/User/ChangePassPerform?code=${otp.join("")}`,
              newUserInfos
            )
            .then((res) => {
              if (res.status === 200) {
                setRecoveryStep(3);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "کد تایید را اشتباه وارد کردید.",
                });
              }
            })
            .catch((err) => console.log("err", err))
            .finally(() => setIsSendingData(false));
        } else {
          await passwordSchema.validate(validationData, {
            abortEarly: false,
          });
        }
      } catch (errors) {
        const newErrors = {};

        errors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        console.log("errors", newErrors);
        setFormErrors(newErrors);
      }
    } else {
      setConfirmCodeError(true);
    }
  }

  function handleFinalStep() {
    navigate("/login");
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <MuiRTL>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <video autoPlay loop muted style={styles.videoBackground}>
            <source src="./videos/istockphoto.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className="blurredBackground"
          >
            <div className="login-form" style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Link to="/" className="logo" />
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontFamily: "IRANSans", color: "#042b89" }}
                >
                  {recoveryStep === 1
                    ? "بازیابی رمز عبور"
                    : recoveryStep === 2
                    ? "کد تایید"
                    : "ساخت رمز عبور جدید"}
                </Typography>
              </div>
              {recoveryStep === 2 && (
                <p style={{ fontWeight: "bold", marginTop: "1rem" }}>
                  کد تایید برای {formData.phone} ارسال شد.
                </p>
              )}
              <form
                style={{ fontFamily: "IRANSans" }}
                noValidate
                onSubmit={
                  recoveryStep === 1
                    ? handleSubmit
                    : recoveryStep === 2
                    ? handleSecondStep
                    : handleFinalStep
                }
              >
                {recoveryStep === 1 ? (
                  <>
                    <TextField
                      style={{ fontFamily: "IRANSans" }}
                      margin="normal"
                      required
                      fullWidth
                      id="userName"
                      label="نام کاربری"
                      name="userName"
                      autoFocus
                    />
                    {formErrors.userName && (
                      <span className="register-err">
                        {formErrors.userName}
                      </span>
                    )}
                    <TextField
                      style={{ fontFamily: "IRANSans" }}
                      margin="normal"
                      required
                      fullWidth
                      id="phone"
                      label="تلفن همراه"
                      name="phone"
                      autoFocus
                    />
                    {formErrors.phone && (
                      <span className="register-err">{formErrors.phone}</span>
                    )}
                  </>
                ) : recoveryStep === 2 ? (
                  <>
                    <OTPInput otp={otp} setOtp={setOtp} />
                    {confirmCodeError && (
                      <p
                        style={{
                          fontSize: "1.5rem",
                          textAlign: "center",
                          color: "#f00",
                        }}
                      >
                        لطفا کد ارسال شده را وارد نمایید.
                      </p>
                    )}

                    <FormControl
                      sx={{
                        width: "100%",
                        marginTop: "1rem",
                        justifyContent: "center",
                      }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="password">رمز</InputLabel>
                      <OutlinedInput
                        required
                        style={{ fontSize: "1.5rem" }}
                        name="password"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff style={{ fontSize: "2.5rem" }} />
                              ) : (
                                <Visibility style={{ fontSize: "2.5rem" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="password"
                      />
                    </FormControl>
                    {formErrors.password && (
                      <span className="register-err">
                        {formErrors.password}
                      </span>
                    )}

                    <FormControl
                      sx={{
                        width: "100%",
                        marginTop: "1rem",
                        justifyContent: "center",
                      }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="confirmPassword">
                        تکرار رمز
                      </InputLabel>
                      <OutlinedInput
                        required
                        style={{ fontSize: "1.5rem" }}
                        name="confirmPassword"
                        id="confirmPassword"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff style={{ fontSize: "2.5rem" }} />
                              ) : (
                                <Visibility style={{ fontSize: "2.5rem" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="confirmPassword"
                      />
                    </FormControl>

                    {formErrors.confirmPassword && (
                      <span className="register-err">
                        {formErrors.confirmPassword}
                      </span>
                    )}
                  </>
                ) : (
                  <p
                    style={{
                      fontSize: "2.5rem",
                      textAlign: "center",
                      marginTop: "2rem",
                    }}
                  >
                    رمز عبور با موفقیت تغییر یافت.
                  </p>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isSendingData}
                  className="login-form-btn-successfuly"
                >
                  {isSendingData ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "#fff" }} />
                    </Box>
                  ) : (
                    <p style={{ fontFamily: "IRANSans", color: "white" }}>
                      {recoveryStep === 1
                        ? "ادامه"
                        : recoveryStep === 2
                        ? "تایید"
                        : "وارد شوید"}
                    </p>
                  )}
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiRTL>
  );
}
