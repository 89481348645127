import React, { useState, useEffect } from "react";
import { TextField, Button, IconButton, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { setHeaders } from "../../utils";
import axios from "axios";

const Parameters = ({ setParameters, link, keyID }) => {
  const [commands, setCommands] = useState([]);
  const [parametersIncluds, setParametersIncluds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataReceived, setDataReceived] = useState(false); 
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");

  const handleParamNameChange = (keyID, newName) => {
    const updatedParametersIncluds = parametersIncluds.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramName: newName };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };

  const handleParamValueChange = (keyID, newValue) => {
    const updatedParametersIncluds = parametersIncluds.map((p) => {
      if (p.keyID === keyID) {
        return { ...p, paramValue: newValue };
      }
      return p;
    });
    setParametersIncluds(updatedParametersIncluds);
  };

  const handleRemoveCommand = (index) => {
    const updatedCommands = commands.filter((_, i) => i !== index);
    setCommands(updatedCommands);
  };

  const handleRemoveParametersIncludes = (index) => {
    const updatedParametersIncluds = parametersIncluds.filter(
      (i) => i.keyID !== index
    );
    setParametersIncluds(updatedParametersIncluds);
  };

  const getApiData = (link) => {
    const headers = setHeaders();

    setIsLoading(true);
    axios
      .get(link, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setParametersIncluds(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (link) {
      getApiData(link);
    }
  }, [link]);

  useEffect(() => {
    if (parametersIncluds.length > 0) {
      setDataReceived(true);
    }
  }, [parametersIncluds]);

  return (
    <div>
      {/* <MuiRTL> */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {commands.map((command, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                padding: "1rem",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    label="عنوان"
                    variant="filled"
                    fullWidth
                    value={command.paramTitle}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="مقدار"
                    variant="filled"
                    fullWidth
                    value={command.paramValue}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => handleRemoveCommand(index)}
                    color="secondary"
                  >
                    <CancelIcon style={{ color: "rgba(176,56,60,255)" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}

          {parametersIncluds && parametersIncluds.length ? (
            parametersIncluds.map((p) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ padding: "1rem" }}
              >
                <Grid item xs={6}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    label="عنوان"
                    variant="filled"
                    fullWidth
                    value={p.paramName}
                    onChange={(e) =>
                      handleParamNameChange(p.keyID, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    style={{ fontFamily: "IRANSans" }}
                    label="مقدار"
                    variant="filled"
                    fullWidth
                    value={p.paramValue}
                    onChange={(e) =>
                      handleParamValueChange(p.keyID, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={1}>
              
                  <IconButton
                    onClick={() => handleRemoveParametersIncludes(p.keyID)}
                    color="secondary"
                  >
                    <CancelIcon style={{ color: "rgba(176,56,60,255)" }} />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ padding: "1rem" }}
            >
              <Grid item xs={6}>
                <TextField
                  style={{ fontFamily: "IRANSans" }}
                  label="عنوان"
                  variant="filled"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  style={{ fontFamily: "IRANSans" }}
                  label="مقدار"
                  variant="filled"
                  fullWidth
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default Parameters;
