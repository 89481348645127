import React, { useEffect, useState } from "react";
import { Button, Modal, Paper } from "@mui/material";
import { setHeaders } from "../../utils";
import axios from "axios";
import { HOST_NAME } from "../../config";
import Swal from "sweetalert2";

import MultiImageUploader from "./MultiImageUploader";
import CircularProgressBar from "../../Components/CircularProgressBar/CircularProgressBar";
import DisplayImages from "../../Components/DisplayImage/DisplayImage";

export default function ModalAttachExeFile({
  isOpen,
  onClose,
  Idd,
  refresh,
  exerFileLimit,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [filesProgress, setFilesProgress] = useState(0);

  useEffect(() => {
    const headers = setHeaders();
    axios
      .get(`${HOST_NAME}/api/v1/Exercise/${Idd}/ExerciseFiles`, { headers })
      .then((res) => {
        res.status === 200 && setCurrentFiles(res.data);
      })
      .catch((error) => {
        console.error("خطا در درخواست به API: ", error);
      });
  }, []);

  function uploadFiles() {
    if (newFiles.length === 0) {
      Swal.fire({
        icon: "error",
        text: `فایلی برای آپلود انتخاب نکرده اید.`,
      });
      return;
    }
    setIsUploading(true);
    const headers = setHeaders();
    axios
      .get(
        `${HOST_NAME}/api/v1/Exercise/${Idd}/ExerciseFiles/GetRemCountOfFiles`,
        { headers }
      )
      .then((res) => {
        if (res.data.result >= newFiles.length) {
          let uploadFiles = axios.post(
            `${HOST_NAME}/api/v1/Exercise/${Idd}/ExerciseFiles`,
            newFiles.map(({ preview, ...rest }) => rest),
            {
              headers,
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.floor((loaded * 100) / total);
                setFilesProgress(percentCompleted);
              },
            }
          );

          Promise.all([uploadFiles])
            .then(() => {
              setIsUploading(false);
              Swal.fire({
                icon: "success",
                text: "فایل ها با موفقیت آپلود شدند",
              });
              refresh();
              onClose();
            })
            .catch((err) => {
              setIsUploading(false);
            });
        } else if (res.data.result === 0) {
          Swal.fire({
            icon: "error",
            text: `به حداکثر تعداد مجاز برای آپلود فایل (${exerFileLimit} عدد) رسیده اید.`,
          });
          setIsUploading(false);
        } else {
          Swal.fire({
            icon: "error",
            text: `تنها ${res.data.result} فایل دیگر مجاز هستید آپلود کنید.`,
          });
          setIsUploading(false);
        }
      })
      .catch((err) => console.log("err", err));
  }

  function removeSliderImage(e, imageKeyID, isImage) {
    const headers = setHeaders();
    e.preventDefault();
    axios
      .delete(
        `${HOST_NAME}/api/v1/Exercise/${Idd}/ExerciseFiles/${imageKeyID},${isImage}`,
        {
          headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: `فایل حذف شد.`,
          });

          setCurrentFiles((prevState) =>
            prevState.filter((img) => img?.keyID !== imageKeyID)
          );
        }
      })
      .catch((err) => console.log("err", err));
  }

  return (
    <Modal className="modal-add-edit" open={isOpen} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          padding: 2,
        }}
      >
        <div style={{ flex: 1, padding: "0 10px" }}>
          <h2>افزودن فایل های تمرین</h2>
          <hr style={{ borderTop: "2px solid blue", margin: "10px 0" }} />

          {currentFiles.filter((img) => img.fileDescription !== "main").length >
            0 && <p>فایل های آپلود شده</p>}
          <div className="slider-images-container">
            <DisplayImages
              filesData={currentFiles}
              onDeleteFile={removeSliderImage}
              prop="keyID"
            ></DisplayImages>
          </div>

          <MultiImageUploader setImages={setNewFiles} images={newFiles} />

          {isUploading && (
            <div className="upload-percent-container">
              <div>
                <span>درصد آپلود فایل های چندتایی</span>
                <CircularProgressBar percentage={filesProgress} />
              </div>
            </div>
          )}
          <Button
            variant="contained"
            fullWidth
            onClick={uploadFiles}
            sx={{ marginTop: 2, marginBottom: "1rem" }}
          >
            آپلود
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
