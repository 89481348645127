import React, { useState, useEffect } from "react";

import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Box,
  CircularProgress,
  FormControl,
  Snackbar,
  TextField,
  FormGroup,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { Add, Delete, Edit } from "@mui/icons-material";
import MuiRTL from "../MuiRTL/MuiRTL";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { Android12Switch, setHeaders } from "../../utils";
import { HOST_NAME } from "../../config";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialValues = {
  fName: "",
  lName: "",
  email: "",
  mobile1: "",
  nationalCode: "",
  description: "",
  gender: "",
  birthDate: "",
  patient_addresses: "",
};

const validationSchema = yup.object().shape({
  fName: yup.string().required("نام الزامی است"),
  lName: yup.string().required("نام خانوادگی الزامی است"),
  mobile1: yup
    .string()
    .matches(
      /^\d{10}$/,
      "شماره تلفن معتبر نیست. بدون 0 ابتدایی و 10 رقمی وارد کنید"
    )
    .required("شماره تلفن الزامی است"),
});

export default function ClientRightSide({
  setDataForShowInformation,
  dataForShowInformation,
  setSelectedClient,
  setIsLodingGetPackageClient,
  setClientsPackage,
}) {
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [allClients, setAllClients] = useState([]);
  const [isShowDetailInformation, setIsShowDetailInformation] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [isLodingGetData, setIsLodingGetData] = useState(false);
  const [countPage, setCountPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isEditingClient, setIsEditingClient] = useState(false);

  const [search, setSearch] = useState("");

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const getClientPackage = (kyid) => {
    setIsLodingGetPackageClient(true);
    if (kyid) {
      const headers = setHeaders();

      axios
        .get(`${HOST_NAME}/api/v1/Patient/${kyid}/Packages`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setClientsPackage(res.data);
            setIsLodingGetPackageClient(false);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const getClientInformation = (pageNumber) => {
    setIsLodingGetData(true);
    const headers = setHeaders();

    const pageSize = 8;
    axios
      .get(`${HOST_NAME}/api/v1/Patient/${pageNumber},${pageSize}`, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setTableData(res.data.patientModelList);
          setCountPage(Math.ceil(res.data.totalcount / pageSize));
          setIsLodingGetData(false);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (search) {
      searchHandler(currentPage);
    } else {
      if (!currentPage) {
        getClientInformation(1);
      } else {
        getClientInformation(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    searchHandler(1);
  }, [search]);

  function searchHandler(pageNumber) {
    if (search.length >= 1) {
      setIsLodingGetData(true);
      const headers = setHeaders();

      const pageSize = 8;
      axios
        .get(
          `${HOST_NAME}/api/v1/Patient/GetByFilter?pageNumber=${pageNumber}&pageSize=${pageSize}&strFilter=${search}`,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setTableData(res.data.patientModelList);
            setCountPage(Math.ceil(res.data.totalcount / pageSize));
            setIsLodingGetData(false);
          }
        })
        .catch((e) => console.log(e));
    } else {
      getClientInformation(1);
    }
  }

  function handleSearch(searchValue) {
    setSearch(searchValue);
  }

  const handleAddRow = () => {
    setSelectedRow(null);
    setModalOpen(true);
    setUserIsLoggedIn(true);
  };

  const handleEditRow = (index) => {
    setSelectedRow(tableData[index]);
    setModalOpen(true);
  };

  const handleUpdateRow = (updatedRow) => {
    const newData = tableData.map((row) =>
      row === selectedRow ? updatedRow : row
    );

    setTableData(newData);
  };

  const handleDeleteClients = (id, index) => {
    Swal.fire({
      title: `آیا مطمئن هستید که بیمار حذف شود؟`,
      icon: "warning",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      showCancelButton: true,
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = setHeaders();

        axios
          .delete(`${HOST_NAME}/api/v1/Patient/${id}`, {
            headers,
          })
          .then((res) => {
            if (!res.data) {
              setTableData(tableData.filter((_, i) => i !== index));
            } else {
              Swal.fire({
                title: res.data,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("خطا در ارتباط با سرور: ", error);
          });
      }
    });
  };

  const handleFormSubmit = (isEditingClient) => (values, actions) => {
    if (isEditingClient) {
      const newData = [...tableData];
      const index = tableData.indexOf(selectedRow);
      newData[index] = values;
      setTableData(newData);
      setAllClients(newData);
    } else {
      setTableData([...tableData, values]);
      setAllClients([...tableData, values]);
    }

    actions.resetForm();
    setSelectedRow(null);
    setModalOpen(false);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSubmit = (values) => {
    const newUserInfos = {
      keyID: 0,
      fName: values.fName,
      lName: values.lName,
      email: values.email,
      gender: +values.gender,
      mobile1: values.mobile1,
      nationalCode: "0",
      address: values.patient_addresses,
      ticketAllowed: !!+values.ticketAllowed,
      nnAme: "string",
      birthDate: "2023-10-05T07:06:07.123Z",
      mobile2: "string",
      description: values.description,
      cityid: 0,
      postalCode: "string",
      tell: "string",
      fax: "string",
    };

    const headers = setHeaders();

    axios
      .post(`${HOST_NAME}/api/v1/Patient`, newUserInfos, {
        headers,
      })
      .then((res) => {
        res.status === 200 && getClientInformation(currentPage || 1);
      })
      .catch((error) => {
        console.error("خطا در ارسال درخواست:", error);
      });

    setModalOpen(false);
  };

  const editClient = (client, values, actions) => {
    const headers = setHeaders();

    const newPatientInfos = {
      keyID: client.keyID,
      fName: values.fName,
      lName: values.lName,
      nnAme: "string",
      nationalCode: "0",
      birthDate: "2023-10-09T14:12:31.806Z",
      gender: +values.gender,
      ticketAllowed: !!+values.ticketAllowed,
      mobile1: values.mobile1,
      mobile2: "string",
      email: values.email,
      description: values.description,
      cityid: 1,
      postalCode: "string",
      tell: "string",
      fax: "string",
      address: "string",
    };

    axios
      .put(`${HOST_NAME}/api/v1/Patient`, newPatientInfos, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          const updatedData = [...tableData];
          const index = updatedData.findIndex(
            (item) => item.keyID === client.keyID
          );
          if (index !== -1) {
            updatedData[index] = { ...newPatientInfos };
            setTableData(updatedData);
          }
        }
      })
      .catch((error) => {
        console.error("خطا در ارتباط با سرور: ", error);
      });
    actions.resetForm();
    setSelectedRow(null);

    setModalOpen(false);
    setOpenAlert(true);
    setIsEditingClient(false);
  };

  function changeTicketStatus(e, client) {
    e.stopPropagation();
    const headers = setHeaders();

    const { gender, ticketAllowed, cityId, postalCode, tell, fax, address } =
      client;

    const newPatientInfos = {
      ...client,
      gender: +gender,
      ticketAllowed: !ticketAllowed,
      cityid: cityId,
      postalCode: postalCode || "string",
      tell: tell || "string",
      fax: fax || "string",
      address: address || "string",
    };

    axios
      .put(`${HOST_NAME}/api/v1/Patient`, newPatientInfos, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setDataForShowInformation(newPatientInfos);
          const updatedData = [...tableData];
          const index = updatedData.findIndex(
            (item) => item.keyID === client.keyID
          );
          if (index !== -1) {
            updatedData[index] = {
              ...updatedData[index],
              ticketAllowed: !ticketAllowed,
            };
            setTableData(updatedData);
          }
        }
      })
      .catch((error) => {
        console.error("خطا در ارتباط با سرور: ", error);
      });
  }

  return (
    <div className="client-right-side">
      <SearchBar handleSearch={handleSearch} />

      <div>
        <div>
          <div className="new-client-btn">
            <div onClick={handleAddRow}>
              <IconButton>
                <Add fontSize="large" style={{ color: "white" }} />
              </IconButton>

              <p style={{ fontSize: "1.2rem", color: "white" }}>بیمار جدید</p>
            </div>
          </div>

          {isLodingGetData ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                margin: "1.5rem",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer className="clients-table">
              {tableData && tableData.length ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          نام
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          نام خانوادگی
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          جنسیت
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          ایمیل
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          شماره تلفن
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          علت مراجعه
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          نمایش پیام
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          حذف
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "IRANSans",
                            color: "#1876d3",
                            fontSize: "1.2rem",
                          }}
                        >
                          ویرایش
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row, index) => (
                        <TableRow
                          key={index}
                          className={
                            selectedRowIndex === index ? "selected-row" : ""
                          }
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedRowIndex(index);
                            setDataForShowInformation(row);
                            setSelectedClient(row);
                            setIsShowDetailInformation(true);
                            setClientsPackage && getClientPackage(row.keyID);
                          }}
                        >
                          <TableCell style={{ fontFamily: "IRANSans" }}>
                            {row.fName}
                          </TableCell>
                          <TableCell style={{ fontFamily: "IRANSans" }}>
                            {row.lName}
                          </TableCell>
                          <TableCell style={{ fontFamily: "IRANSans" }}>
                            {row.gender === 1 ? "زن" : "مرد"}
                          </TableCell>
                          <TableCell style={{ fontFamily: "IRANSans" }}>
                            {row.email}
                          </TableCell>
                          <TableCell style={{ fontFamily: "IRANSans" }}>
                            {row.mobile1} {row.mobile2}
                          </TableCell>
                          <TableCell style={{ fontFamily: "IRANSans" }}>
                            {row.description}
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              onClick={(e) => changeTicketStatus(e, row)}
                              control={
                                <Android12Switch checked={row.ticketAllowed} />
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Delete
                              onClick={() => {
                                handleDeleteClients(row.keyID, index);
                              }}
                              fontSize="large"
                            />
                          </TableCell>
                          <TableCell>
                            <Edit
                              onClick={(e) => {
                                handleEditRow(index);
                                setIsEditingClient(true);
                              }}
                              fontSize="large"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </TableContainer>
          )}

          <div>
            {!isLodingGetData ? (
              <MuiRTL>
                <Stack
                  spacing={2}
                  direction="rtl"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <Pagination
                    count={countPage}
                    page={currentPage}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </MuiRTL>
            ) : null}
          </div>

          {/* مودال فرم باکس */}
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            selectedRow={selectedRow}
            handleAddRow={handleAddRow}
            handleUpdateRow={() => {
              handleUpdateRow();
            }}
          >
            <div className="new-client-modal">
              <Formik
                initialValues={
                  selectedRow !== null ? selectedRow : initialValues
                }
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  if (isEditingClient) {
                    editClient(selectedRow, values, actions);
                  } else if (userIsLoggedIn) {
                    handleSubmit(values);
                  } else {
                    handleFormSubmit(selectedRow !== null);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <Form>
                    <div style={{ margin: "2rem" }}>
                      <Field
                        as={TextField}
                        label="نام"
                        name="fName"
                        fullWidth
                        error={touched.fName && Boolean(errors.fName)}
                        helperText={touched.fName && errors.fName}
                      />
                    </div>
                    <div style={{ margin: "2rem" }}>
                      <Field
                        as={TextField}
                        label="نام خانوادگی"
                        name="lName"
                        fullWidth
                        error={touched.lName && Boolean(errors.lName)}
                        helperText={touched.lName && errors.lName}
                      />
                    </div>
                    <div style={{ margin: "2rem" }}>
                      <Field
                        as={TextField}
                        label="ایمیل"
                        name="email"
                        fullWidth
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </div>
                    <div style={{ margin: "2rem" }}>
                      <Field
                        as={TextField}
                        label="شماره تلفن"
                        name="mobile1"
                        fullWidth
                        error={touched.mobile1 && Boolean(errors.mobile1)}
                        helperText={touched.mobile1 && errors.mobile1}
                      />
                    </div>
                    <div style={{ margin: "2rem" }}>
                      <Field
                        as={TextField}
                        label="علت مراجعه"
                        name="description"
                        fullWidth
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={touched.description && errors.description}
                      />
                    </div>

                    <FormControl
                      component="fieldset"
                      style={{ margin: "2rem" }}
                    >
                      <FormLabel component="legend">جنسیت</FormLabel>
                      <RadioGroup
                        row
                        style={{ fontFamily: "IRANSans" }}
                        aria-label="جنسیت"
                        name="gender"
                        value={values.gender}
                        onChange={handleChange("gender")}
                      >
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="مرد"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="زن"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl
                      component="fieldset"
                      style={{ margin: "2rem" }}
                    >
                      <FormLabel component="legend">نمایش پیام</FormLabel>
                      <RadioGroup
                        row
                        style={{ fontFamily: "IRANSans" }}
                        aria-label="نمایش پیام"
                        name="ticketAllowed"
                        value={+values.ticketAllowed ? 1 : 0}
                        onChange={handleChange("ticketAllowed")}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="فعال"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="غیرفعال"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          margin: "2rem",
                          color: "#fff",
                          backgroundColor: "rgb(24, 118, 211)",
                          width: "100px",
                          fontSize: "1.2rem",
                          padding: "1rem",
                        }}
                      >
                        ثبت
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            sx={{
              width: "100%",
              fontFamily: "IRANSans",
              fontSize: "1.3rem",
            }}
          >
            بروز رسانی بیمار
            {dataForShowInformation && dataForShowInformation.fName}
            {dataForShowInformation && dataForShowInformation.lName} با موفقیت
            انجام شد.
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
